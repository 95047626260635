import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
import { icons8crossFilled } from "../../assets";
import InputField from "../form/input";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReminders,
  addReminder,
  updateReminders,
  toggleReminder,
} from "../../redux/reminderThunk";
import { fetchCustomers } from "../../redux/customersThunk";
import { toast } from "react-toastify";
import { InputFields } from "../InputField/InputFIelds";
import { RiEditBoxFill } from "react-icons/ri";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMdNotificationsOff } from "react-icons/io";
import { IoMdNotifications } from "react-icons/io";

export const Reminder = () => {
  const dispatch = useDispatch();
  const { list: reminders, loading: remindersLoading } = useSelector(
    (state) => state.reminders
  );
  const { list: customers, loading: customersLoading } = useSelector(
    (state) => state.customers
  );

  const initialFormData = {
    title: "",
    message: "",
    targetCustomerStatus: "",
    triggerCondition: "",
    isActive: false,
  };

  const [addReminderDialogOpen, setAddReminderDialogOpen] = useState(false);
  const [editReminderDialogOpen, setEditReminderDialogOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [errors, setErrors] = useState({});
  const [isAllActiveSelected, setIsAllActiveSelected] = useState(false);

  const [customerTypes, setCustomerTypes] = useState([
    { title: "All Active Customers", value: "allActive" },
    { title: "Customer With Specfic Plan", value: "spcficPlan" },
  ]);

  const [triggerConditions, setTrigerConditions] = useState([
    {
      title: "After a service/product booking",
      value: "after a service/product booking",
    },
  ]);

  useEffect(() => {
    dispatch(fetchReminders());
    // dispatch(fetchCustomers());
    console.log(reminders, "reminders");
  }, [dispatch]);

  const resetForm = () => {
    setFormData(initialFormData);
    setIsAllActiveSelected(false);
  };

  const getChangedFields = (originalData, formData) => {
    const changedFields = {};

    for (const key in formData) {
      if (formData[key] !== originalData[key]) {
        changedFields[key] = formData[key];
      }
    }

    return changedFields;
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) newErrors.title = "Title is required.";
    if (!formData.message.trim()) newErrors.message = "Message is required.";
    // if (!formData.targetCustomers.trim() && !isAllActiveSelected)
    //   newErrors.targetCustomers = "Target Customers are required.";
    if (!formData.triggerCondition.trim())
      newErrors.triggerCondition = "Trigger Condition is required.";

    setErrors(newErrors);

    Object.keys(newErrors).forEach((key) => {
      toast.error(newErrors[key]);
    });

    return Object.keys(newErrors).length === 0;
  };

  const handleAddReminder = () => {
    if (!validateForm()) return;

    console.log(customers, "active");

    const targetCustomers = isAllActiveSelected
      ? customers
          .filter((customer) => customer.isActive)
          .map((customer) => customer._id)
      : [];

    const reminderData = {
      title: formData.title,
      message: formData.message,
      targetCustomerStatus: formData?.targetCustomerStatus,
      triggerCondition: formData.triggerCondition,
    };

    // console.log(reminderData, "reminderData");

    dispatch(addReminder(reminderData)).then(() => {
      setAddReminderDialogOpen(false);
      resetForm();
    });
  };

  const handleToggleReminder = (id, status) => {
    const payload = {
      isActive: status,
    };

    dispatch(
      toggleReminder({
        id,
        status: payload,
      })
    );
  };

  const handleEditReminder = (reminder) => {
    setFormData({
      title: reminder.title,
      message: reminder.message,
      targetCustomerStatus: reminder.targetCustomerStatus,
      triggerCondition: reminder.triggerCondition,
    });
    setSelectedReminder(reminder);
    setEditReminderDialogOpen(true);
  };

  const handleUpdateReminder = () => {
    if (!validateForm()) return;

    const updatedReminder = {
      ...formData,
    };

    dispatch(
      updateReminders({
        id: selectedReminder._id,
        updatedReminder,
      })
    ).then(() => {
      setEditReminderDialogOpen(false);
      resetForm();
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(name, value, "form"); // Debug statement
  };
  const handleCheckboxChange = (e) => {
    setIsAllActiveSelected(e.target.checked);
    if (e.target.checked) {
      setFormData({
        ...formData,
        targetCustomers: "",
      });
    }
  };

  const handleSelectChange = (e) => {
    setIsAllActiveSelected(e.target.value === "allActive");
  };

  return (
    <div>
      <div className="h-[calc(100vh-120px)] overflow-y-auto">
        <div className="flex px-5 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
          <p className="text-black text-2xl">All Reminders</p>
          <PrimaryButton
            onClick={() => setAddReminderDialogOpen(true)}
            title={"Add Reminder"}
          />
        </div>
        <div className="flex p-5 w-full">
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-10">
            {reminders.map((item) => (
              <div key={item._id} className="border p-8 rounded">
                <p className="text-xl font-semibold p-2">{item.title}</p>
                <p className="text-md py-2 pl-2">Message: {item.message}</p>
                <p className="text-sm p-2">Trigger: {item.triggerCondition}</p>
                <div className="flex mt-4 justify-between px-2">
                  <PrimaryButton
                    onClick={() => handleEditReminder(item)}
                    title="Edit"
                    icon={<RiEditBoxFill size={15} />}
                  />

                  {item?.isActive ? (
                    <span>
                      <PrimaryButton
                        onClick={() => handleToggleReminder(item._id, "false")}
                        title="Disable"
                        icon={<IoMdNotificationsOff size={15} />}
                      />
                    </span>
                  ) : (
                    <span>
                      <PrimaryButton
                        onClick={() => handleToggleReminder(item._id, "true")}
                        title="Enable"
                        icon={<IoMdNotifications size={15} />}
                      />
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Add Reminder Dialog */}
        <Dialog
          open={addReminderDialogOpen}
          onClose={() => setAddReminderDialogOpen(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4 min-h-[350px]">
            <Dialog.Panel className="max-w-5xl rounded bg-white p-4  min-h-[350px]">
              <div className="pb-2">
                <img
                  src={icons8crossFilled}
                  onClick={() => setAddReminderDialogOpen(false)}
                  className="w-8 h-8 cursor-pointer ml-auto"
                />
              </div>

              <div className="grid grid-cols-2 gap-5 mt-5">
                <InputFields
                  type="text"
                  title="Title"
                  placeholder="Title"
                  value={formData.title}
                  name="title"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Message"
                  placeholder="Message"
                  value={formData.message}
                  name="message"
                  onChange={handleInputChange}
                />
                {/* <InputFields
                  type="text"
                  title="Target Customers"
                  placeholder="Target Customers (comma separated)"
                  value={formData.targetCustomers}
                  name="targetCustomers"
                  onChange={handleInputChange}
                  disabled={isAllActiveSelected}
                /> */}
                {/* <InputFields
                  type="text"
                  title="Trigger Condition"
                  placeholder="Trigger Condition"
                  value={formData.triggerCondition}
                  name="triggerCondition"
                  onChange={handleInputChange}
                /> */}

                <div>
                  <label className="mb-1 text-sm font-medium">
                    Trigger Condition
                  </label>

                  <div className="flex flex-row mt-1">
                    <select
                      id="triggerCondition"
                      name="triggerCondition"
                      value={formData.triggerCondition}
                      onChange={handleInputChange}
                      className=" border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"

                      //   className="py-4 px-4 border rounded"
                    >
                      {triggerConditions?.map((ele) => {
                        return (
                          <>
                            <option value="hey">
                              Select Trigger Conditions
                            </option>
                            <option value={ele?.value}>{ele?.title}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <label className="mb-1 text-sm font-medium">
                    Target Customers
                  </label>

                  <div className="flex flex-row mt-1">
                    <select
                      id="targetCustomerStatus"
                      name="targetCustomerStatus"
                      value={formData?.targetCustomerStatus}
                      onChange={handleInputChange}
                      //   className="py-4 px-4 border rounded"
                      className=" border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                    >
                      {customerTypes?.map((ele) => {
                        return (
                          <>
                            {/* <option value="">Select Target Customers</option> */}
                            <option value={ele?.value}>{ele?.title}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-10">
                <PrimaryButton
                  onClick={handleAddReminder}
                  title="Add Reminder"
                  loading={remindersLoading || customersLoading}
                />
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>

        <Dialog
          open={editReminderDialogOpen}
          onClose={() => {
            resetForm();
            setEditReminderDialogOpen(false);
          }}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4">
            <Dialog.Panel className="max-w-4xl rounded bg-white p-4 min-h-[350px]">
              <div className="pb-1">
                <img
                  src={icons8crossFilled}
                  onClick={() => {
                    setEditReminderDialogOpen(false);
                    resetForm();
                  }}
                  className="w-8 h-8 cursor-pointer ml-auto"
                />
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <InputFields
                  type="text"
                  title="Title"
                  placeholder="Title"
                  value={formData.title}
                  name="title"
                  onChange={handleInputChange}
                />
                <InputFields
                  type="text"
                  title="Message"
                  placeholder="Message"
                  value={formData.message}
                  name="message"
                  onChange={handleInputChange}
                />
                <div>
                  <label className="mb-1 text-sm font-medium">
                    Trigger Condition
                  </label>

                  <div className="flex flex-row mt-1">
                    <select
                      id="triggerCondition"
                      name="triggerCondition"
                      value={formData.triggerCondition}
                      onChange={handleInputChange}
                      //   className="py-4 px-4 border rounded"
                      className=" border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                    >
                      {triggerConditions?.map((ele) => {
                        return (
                          <>
                            <option value="hey">
                              Select Trigger Conditions
                            </option>
                            <option value={ele?.value}>{ele?.title}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div>
                  <label className="mb-1 text-sm font-medium">
                    Target Customers
                  </label>

                  <div className="flex flex-row mt-1">
                    <select
                      id="targetCustomerStatus"
                      name="targetCustomerStatus"
                      value={formData?.targetCustomerStatus}
                      onChange={handleInputChange}
                      //   className="py-4 px-4 border rounded"
                      className=" border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                    >
                      {customerTypes?.map((ele) => {
                        return (
                          <>
                            <option value={ele?.value}>{ele?.title}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex justify-center mt-10">
                <PrimaryButton
                  onClick={handleUpdateReminder}
                  title="Update Reminder"
                  loading={remindersLoading}
                  //   loading={loading}
                />
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
