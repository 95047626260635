import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setUser } from "./userSlice";
import { notifyError, notifySuccess } from "../utility/axiosInstance";
import { userDetails } from "../utility/api";
import axiosInstance from "../utility/axiosInstance";

export const fetchUserDetails = createAsyncThunk(
  "user/fetchUserDetails",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(userDetails.getUserDetails);
      dispatch(setUser(response.data?.data));
    } catch (error) {
      notifyError(`Failed to fetch user details:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "user/updateUserDetails",
  async ({ id, updatedUserDetails }, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      await axiosInstance.put(
        `${userDetails?.updateUserDetails}`,
        updatedUserDetails
      );

      dispatch(fetchUserDetails());

      notifySuccess("User details has been updated");
    } catch (error) {
      notifyError(`Failed to update user details:, ${error}`);
    }
  }
);
