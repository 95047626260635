import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setUsedCars,
  setLoading,
  addUsedCarsSuccess,
  deleteUsedCarsSuccess,
  updateUsedCarsSuccess,
} from "./usedCarsSlice";
import { usedCars } from "../utility/api";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../utility/axiosInstance";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


export const fetchUsedCars = createAsyncThunk(
  "usedCars/fetchUsedCars",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(usedCars.getAllUsedCars);
      dispatch(setUsedCars(response.data?.data));
    } catch (error) {
      notifyError(`Failed to fetch used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addUsedCars = createAsyncThunk(
  "usedCars/addUsedCars",
  async (newUsedCars, { dispatch }) => {
    dispatch(setLoading(true));
    try {
       await axiosInstance.post(
        usedCars.postUsedCars,
        newUsedCars
      );
      dispatch(fetchUsedCars());
      notifySuccess("Used car has been added");
    } catch (error) {
      notifyError(`Failed to add used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const updateUsedCars = createAsyncThunk(
  "usedCars/updateUsedCars",
  async ({ id, updatedUsedCars }, { dispatch }) => {
    dispatch(setLoading(true));
    console.log(updateUsedCars, "updateUsedCars");
    try {
      await axiosInstance.put(
        `${usedCars?.updateUsedCars}/${id}`,
        updatedUsedCars
      );
      dispatch(fetchUsedCars());
      notifySuccess("Used car has been updated");
      //   dispatch(updateSparePartSuccess(response.data));
    } catch (error) {
      notifyError(`Failed to update used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const deleteUsedCars = createAsyncThunk(
  "usedCars/deleteUsedCars",
  async (id, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance.delete(`${usedCars?.postUsedCars}/${id}`);
      dispatch(fetchUsedCars());
      notifySuccess("Used car has been deleted");
    } catch (error) {
      notifyError(`Failed to delete used cars:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
