import { createSlice } from "@reduxjs/toolkit";

const usedCarsSlice = createSlice({
  name: "usedCarsSlice",
  initialState: {
    list: [],
    loading: false,
    usedCarRequest: false,
  },
  reducers: {
    setUsedCars: (state, action) => {
      state.list = action.payload;
    },
    setUsedCarsRequest: (state, action) => {
      state.usedCarRequest = action.payload;
    },
    addUsedCarsSuccess: (state, action) => {
      state.list.push(action.payload);
    },
    deleteUsedCarsSuccess: (state, action) => {
      state.list = state.list.filter((part) => part.id !== action.payload);
    },
    updateUsedCarsSuccess: (state, action) => {
      const index = state.list.findIndex(
        (part) => part.id === action.payload.id
      );
      state.list[index] = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setUsedCars,
  addUsedCarsSuccess,
  deleteUsedCarsSuccess,
  updateUsedCarsSuccess,
  setLoading,
  setUsedCarsRequest
} = usedCarsSlice.actions;

export default usedCarsSlice.reducer;
