// utils/resendOtp.js
import { auth } from "./api";
import axiosInstance from "./axiosInstance";
async function resendOtp(email, purpose) {
  try {
    const response = await axiosInstance.post(auth.resendOtp, {
      email: email,
      purpose: purpose,
    });

    return { success: true, message: response?.data?.message };
  } catch (error) {
    console.error("Error resending OTP:", error.message);
    return { success: false, message: error.message };
  }
}

export default resendOtp;
