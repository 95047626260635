import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "../../../css/Calender.css";
import { ServiceSettingContext } from "../ServiceSettingIndex";
import ServiceListComponent from "./ServiceListComponent";
import PrimaryButton from "../../../utils/buttons/primaryButton";
import axiosInstance from "../../../../utility/axiosInstance";
import { toast } from "react-toastify";
import { setAdditionServiceInfo } from "../../../../redux/serviceSlice";
import { useDispatch, useSelector } from "react-redux";

const CalenderComponent = () => {
  const {
    calenderView,
    dateValue,
    workingDayTime,
    workingTime,
    weekdays,
    onDateChange,
    weeklyCheckbox,
    setWorkingDayTime,
    availableWorkingDays,
    setAvailableWorkingDays,

    services,
  } = useContext(ServiceSettingContext);
  const { list, loading, serviceSettingInfo } = useSelector(
    (state) => state?.services
  );
  const dispatch = useDispatch();
  const [available, setAvailable] = useState([]);
  const [allDate, setAllDate] = useState({
    minDate: new Date(),
    maxDate: new Date(),
  });
  const [updateSate, setUpdate] = useState(true);
  useEffect(() => {
    try {
      const tempNAD = [];
      const tempNADT = [];
      let today = new Date();
      today.setDate(today.getDate());
      let count = 7;
      if (calenderView && weeklyCheckbox) {
        switch (calenderView) {
          case "week":
            count = 7;
            break;
          case "fortnight":
            count = 14;
            break;
          case "month":
            count = 28;
            break;

          default:
            break;
        }
        for (const [key, value] of Object.entries(weeklyCheckbox)) {
          if (!Boolean(value.day)) {
            tempNAD.push(key);
          }
        }
        let index2 = 0;
        while (index2 < count) {
          const current = new Date(today);
          current.setDate(current.getDate() + index2);
          const weekday = current.toLocaleDateString("en-US", {
            weekday: "long",
          });

          if (tempNAD.includes(weekday)) {
            index2++;
            continue;
          }
          index2++;
          today = current;
          break;
        }

        let index = 0;
        const current = new Date(today);

        const weekday = current.toLocaleDateString("en-US", {
          weekday: "long",
        });

        while (index < weekdays.length) {
          if (weekdays[index] === weekday) {
            count -= index;
            break;
          }
          index++;
        }
        index = 0;
        const allDates = [];
        while (index < weekdays.length) {
          let tempDate = new Date(today);
          tempDate.setDate(tempDate.getDate() + index);
          const tempWeekday = tempDate.toLocaleDateString("en-US", {
            weekday: "long",
          });
          if (tempNAD.includes(tempWeekday)) {
            tempNADT.push(tempDate.toLocaleDateString());
            // console.log();
          }
          index++;
        }

        for (let index = 0; index < count; index++) {
          let tempDate = new Date(today);
          tempDate.setDate(tempDate.getDate() + index);
          const weekday = tempDate.toLocaleDateString("en-US", {
            weekday: "long",
          });
          if (
            !weeklyCheckbox[weekday].all &&
            !tempNADT.includes(tempDate.toLocaleDateString())
          ) {
            allDates.push(tempDate.toLocaleDateString());
          }
        }
        let lastDay = new Date(today);
        // setAvailableWorkingDays(allDates);

        setAvailable(allDates);
        lastDay.setDate(lastDay.getDate() + count - 1);

        onDateChange(today);
        setAllDate((state) => ({ minDate: today, maxDate: lastDay }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    weeklyCheckbox,
    calenderView,
    weekdays,
    onDateChange,
    setAvailableWorkingDays,
  ]);

  useEffect(() => {
    try {
      if (workingTime) {
        let currentTime = workingTime.start;
        let endTime = workingTime.end;
        let startDate = new Date();
        let startDate2 = new Date();
        let endDate = new Date();
        let [startHour, startMinute] = currentTime.split(":");
        startDate.setHours(startHour, startMinute, 0, 0);
        startDate2.setHours(startHour, startMinute, 0, 0);

        let [endStartHour, endStartMinute] = endTime.split(":");
        endDate.setHours(endStartHour, endStartMinute - 15, 0, 0);
        let min = 0;
        let end = 15;
        const allTime = [];
        let allServices = null;
        if (serviceSettingInfo) {
          const { serviceDateSettings } = serviceSettingInfo;

          allServices = serviceDateSettings?.find(({ date }) => {
            return date === new Date(dateValue).toLocaleDateString();
          });
          console.log(allServices);
        }

        while (startDate < endDate) {
          startDate.setHours(startHour, startMinute + min, 0, 0);
          startDate2.setHours(startHour, startMinute + end, 0, 0);

          const tempTime = `${startDate
            .toLocaleTimeString()
            .slice(0, 5)} - ${startDate2.toLocaleTimeString().slice(0, 5)}`;
          let status = true;
          if (allServices) {
            const exist = allServices.timeSlots.find(
              ({ time }) => time === tempTime
            );
            status = exist ? exist.status : true;
          }
          allTime.push({
            time: tempTime,
            status: status,
          });

          min += 15;
          end += 15;
        }

        setWorkingDayTime(allTime);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setWorkingDayTime, workingTime]);

  useEffect(() => {
    const tempAllDate = [];

    // if (availableWorkingDays.length === 0) {
    if (workingDayTime && available && services) {
      available.forEach((element) => {
        tempAllDate.push({
          date: element,
          services,
          timeSlots: workingDayTime,
        });
      });
      // console.log(tempAllDate);
      setAvailableWorkingDays(tempAllDate);
    }
    // }
  }, [available, services]);

  const handleUpdate = async () => {
    try {
      const temp = availableWorkingDays.map(() => {});
      const response = await axiosInstance.put(
        "servicesSetting/updateServiceSettingInfo",
        {
          serviceDateSettings: availableWorkingDays,
        }
      );

      if (response?.status === 200) {
        console.log(response);
        dispatch(setAdditionServiceInfo(response?.data.serviceDateSettings));
        toast.success("Changed the update");
        // setInfo(response.data.parkingLot);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateChange = async (e) => {
    try {
      console.log(e);

      // const response = await axiosInstance.put(
      //   "servicesSetting/updateServiceSettingInfo",
      //   {
      // serviceDateSettings:[]
      //   }
      // );

      // if (response?.status === 200) {
      //   toast.success("Changed the Status");
      //   // setInfo(response.data.parkingLot);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col flex-wrap gap-5 ">
      <div className="flex flex-wrap justify-between gap-1 w-[30rem]">
        <span className="text-lg text-center">Calender Setting</span>
        <div className="calendar-container">
          <Calendar
            onClickDay={handleDateChange}
            onChange={(e) => {
              onDateChange(e);
            }}
            tileDisabled={({ activeStartDate, date, view }) => {
              let inactive = false;

              if (!available.includes(date.toLocaleDateString())) {
                inactive = true;
              }

              return inactive;
            }}
            tileClassName={({ activeStartDate, date, view }) => {
              let inactive = "";
              if (available.includes(date.toLocaleDateString())) {
                inactive = "react-calendar__tile_available";
              }

              if (!available.includes(date.toLocaleDateString())) {
                inactive = "react-calendar__tile--disabled";
              }

              return inactive;
            }}
            value={dateValue}
            maxDate={allDate.maxDate}
            minDate={allDate.minDate}
            className="custom-calendar"
          />
        </div>
      </div>
      <div className="flex flex-col gap-9  w-[30rem]">
        <div className="flex flex-wrap justify-between gap-10">
          <span className="text-lg text-center">Time Settings</span>
          <span className="text-lg">
            Date: {new Date(dateValue).toDateString()}
          </span>
        </div>
        <TimeSlotComponent />
      </div>
      <div className="flex justify-between">
        <PrimaryButton
          width="120px"
          title={"Update"}
          onClick={handleUpdate}
          // loading={loading}
        />
        {/* <PrimaryButton
          width="120px"
          title={"save"}
          onClick={handleUpdate}
          // loading={loading}
        /> */}
      </div>
    </div>
  );
};

const TimeSlotComponent = () => {
  const {
    workingDayTime,
    setAvailableWorkingDays,
    dateValue,
    availableWorkingDays,
    setWorkingDayTime,
  } = useContext(ServiceSettingContext);
  // console.log("workingDayTime", workingDayTime);
  return (
    <div
      className="bg-[#e5e7eb] p-[15px] rounded-[12px] border-none shadow-md w-full flex gap-8 flex-wrap overflow-y-auto 
     max-h-[25.2rem]"
    >
      {workingDayTime?.map(({ time, status }, i) => (
        <span
          key={i}
          onClick={() => {
            // setWorkingDayTime((prevState) => {
            //   const newState = [...prevState];

            //   newState[i] = {
            //     ...newState[i],
            //     status: !newState[i].status,
            //   };

            //   return newState;
            // });

            const find = availableWorkingDays.find(({ date }) => {
              return date === new Date(dateValue).toLocaleDateString();
            });

            if (find) {
              find.timeSlots.map((item) => {
                if (item.time === time) {
                  item.status = !item.status;
                }
                return item;
              });

              setAvailableWorkingDays((state) => {
                const newState = state.map((item) => {
                  if (item.date === find.date) {
                    return find;
                  }
                  return item;
                });
                return newState;
              });
              setWorkingDayTime(() => [...find.timeSlots]);
            }
          }}
          className={`color-[#111827] cursor-pointer text-lg p-[7px] text-center rounded-[8px] `}
          style={{ backgroundColor: status ? "#6b7280" : "#d1d5db" }}
        >
          {time}
        </span>
      ))}
    </div>
  );
};
export default CalenderComponent;
