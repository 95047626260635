import { createSlice } from "@reduxjs/toolkit";

const reminderSlice = createSlice({
  name: "reminders",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    setReminder: (state, action) => {
      state.list = action.payload;
    },

    deleteReminder: (state, action) => {
      state.list = state.list.filter((part) => part.id !== action.payload);
    },
    updateReminder: (state, action) => {
      const index = state.list.findIndex(
        (part) => part.id === action.payload.id
      );
      state.list[index] = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setReminder, setLoading, updateReminder, deleteReminder } =
  reminderSlice.actions;

export default reminderSlice.reducer;
