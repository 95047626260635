import React, { useEffect, useState } from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
// import PrimaryButton from "../../utils/buttons/primaryButton";
import { Dialog, Switch } from "@headlessui/react";
import ServiceTable from "./ServiceTable";
import { priceIcon, serviceIcon, icons8crossFilled } from "../../assets";
import AcceptButton from "../staticButtons/acceptButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServices,
  createService,
  modifyService,
  deleteServices,
  fetchServicesSettingInfo,
  fetchAdditionServicesInfo,
} from "../../redux/serviceThunk";
import {
  IoFlowerSharp,
  IoSettings,
  IoSettingsOutline,
  IoTime,
} from "react-icons/io5";
import { toast } from "react-toastify";
import { setServiceOptionSelected } from "../../redux/searchSlice";
import ParkingLotModal from "./component/ParkingLotModal";
import axiosInstance, { notifyError } from "../../utility/axiosInstance";
import ServiceSettingIndex from "./ServiceSetting/ServiceSettingIndex";
import { FaArrowLeft } from "react-icons/fa";
import ToggleButton, { ToggleAButton } from "../utils/buttons/toggleButton";

export const Services = () => {
  const [timePeriod, setTimePeriod] = useState(15);
  const [addServices, setAddServices] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [estimatedTime, setEstimatedTime] = useState(15);
  const [isEditMode, setIsEditMode] = useState(false); // New state to track edit mode
  const [OCSetting, setOCSetting] = useState(false); // New state to track edit mode
  const [editingServiceId, setEditingServiceId] = useState(null); // ID of the service being edited
  const [enableBooking, setEnableBooking] = useState(false); // For enabling booking
  const [showHidePL, setShowHidePL] = useState(false); // For enabling booking
  const [availableSlots, setAvailableSlots] = useState(1); // For available slots
  const [EDTimeSlot, setEDTimeSlot] = useState(false); // For available slots
  const [info, setInfo] = useState(0);
  const dispatch = useDispatch();
  const { list, loading, serviceAdditionInfo } = useSelector(
    (state) => state?.services
  );


  const searchTabs = useSelector((state) => state?.search);
  useEffect(() => {
    try {
      if (serviceAdditionInfo) {
        setInfo(serviceAdditionInfo);
        setEDTimeSlot(serviceAdditionInfo.EDTimeSlot);
      }
    } catch (error) {
      notifyError(`Failed to fetch notifications:, ${error}`);
    }
  }, [serviceAdditionInfo]);

  const handleAddOrEditService = () => {
    const newService = {
      serviceName,
      price: parseFloat(servicePrice),
      status: "active",
      estimatedTime: parseFloat(estimatedTime),
      enableBooking,
      availableSlots: parseInt(availableSlots),
    };

    if (!serviceName || !servicePrice || !newService?.estimatedTime) {
      toast.warning("All fields are required");
      return;
    }

    if (isEditMode) {
      dispatch(modifyService({ ...newService, _id: editingServiceId }));
    } else {
      dispatch(createService(newService));
    }

    resetForm();
    setAddServices(false);
  };

  const resetForm = () => {
    setServiceName("");
    setServicePrice("");
    setEstimatedTime(0);
    setEnableBooking(false); // Reset enableBooking
    setAvailableSlots(0); // Reset availableSlots
    setIsEditMode(false);
    setEditingServiceId(null);
  };

  const handleEditService = (service) => {
    setServiceName(service.serviceName);
    setServicePrice(service.price);
    setEstimatedTime(service.estimatedTime);
    setEnableBooking(service.enableBooking); // Set enableBooking
    setAvailableSlots(service.availableSlots); // Set availableSlots
    setEditingServiceId(service._id);
    setIsEditMode(true);
    setAddServices(true);
  };

  useEffect(() => {
    dispatch(setServiceOptionSelected({ isActive: true, searchText: "" }));
    return () => {
      dispatch(setServiceOptionSelected({ isActive: false, searchText: "" }));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchServicesSettingInfo());
    dispatch(fetchAdditionServicesInfo());
  }, [dispatch]);

  const handleToggle = (info, type) => {
    let payload;

    if (type == "enableBooking") {
      const newStatus = info?.enableBooking == true ? false : true;

      payload = {
        _id: info?._id,
        enableBooking: newStatus,
      };
    } else {
      const newStatus = info?.status === "active" ? "inactive" : "active";
      payload = {
        _id: info?._id,
        status: newStatus,
      };
    }

    dispatch(modifyService(payload));
  };
  const handleEDAToggle = async () => {
    try {
      const response = await axiosInstance.put(
        "servicesSetting/EDTimeSlotSetting",
        {
          EDTimeSlot: !EDTimeSlot,
        }
      );

      if (response?.status === 200) {
        setEDTimeSlot(response.data.EDTimeSlot);
        toast.success("Changed the Status");
        // setInfo(response.data.parkingLot);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = (info) => {
    const payload = {
      _id: info?._id,
    };

    dispatch(deleteServices(payload?._id));
  };

  return (
    <div className="h-[calc(100vh-120px)] overflow-y-auto">
      <div className="flex px-5 h-[80px] w-full py-2 justify-between items-center border-b-[2px] border-lightBorderColor">
        {OCSetting ? (
          <FaArrowLeft
            size={25}
            style={{ cursor: "pointer" }}
            onClick={() => setOCSetting(false)}
            color="rgb(153 0 0 / var(--tw-bg-opacity))"
          />
        ) : (
          <p className="text-black text-2xl">All Services</p>
        )}
        <div className="flex px-5 gap-5 items-center">
          {OCSetting ? (
            <>
              <div>
                <PrimaryButton
                  onClick={() => setShowHidePL(true)}
                  title={"Edit Slots"}
                />
                {info?.lotSize?.length !== 0 ? null : (
                  <label className="block text-sm font-medium text-gray-700">
                    Parking Slot is required*
                  </label>
                )}
              </div>
              <div>
                <p className="text-black text-lg ">Auto</p>
                <ToggleAButton
                  // onClick={()=>{}}
                  onClick={handleEDAToggle}
                  checked={EDTimeSlot}
                />
              </div>
            </>
          ) : (
            <>
              <PrimaryButton
                onClick={() => setAddServices(true)}
                title={"Add Services"}
              />
              <IoSettings
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (list?.length !== 0) setOCSetting(true);
                }}
                size={50}
                color={
                  list?.length !== 0
                    ? "rgb(153 0 0 / var(--tw-bg-opacity))"
                    : "grey"
                }
              />
            </>
          )}
        </div>
      </div>
      <div className="flex px-5">
        <div className="w-full my-2 overflow-y-auto">
          {OCSetting ? (
            <ServiceSettingIndex />
          ) : (
            <ServiceTable
              services={list}
              setFormData={setServiceName}
              onToggle={handleToggle}
              onEdit={handleEditService}
              onDelete={handleDelete}
              searchText={searchTabs?.searchText}
            />
          )}
        </div>
      </div>
      <ParkingLotModal
        info={info}
        setInfo={setInfo}
        setShowHidePL={setShowHidePL}
        showHidePL={showHidePL}
      />
      <Dialog
        open={addServices}
        onClose={() => {
          resetForm();
          setAddServices(false);
        }}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/80 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="w-full flex justify-center rounded">
            <div className="h-auto w-[550px] xl:w-[750px] relative rounded-3xl bg-white px-5 py-6">
              <div className="">
                <div className="p-2">
                  <img
                    src={icons8crossFilled}
                    onClick={() => {
                      resetForm();
                      setAddServices(false);
                    }}
                    className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                  />
                </div>
                <div className="pt-2 px-3">
                  <div className="p-5 flex flex-col gap-5">
                    <div className="relative">
                      <input
                        className="border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 pr-[50px] text-lg py-[8px] px-2 lg:pl-3 rounded-md focus:outline-none focus:border-blue-300"
                        type="text"
                        value={serviceName}
                        placeholder="Service Name"
                        onChange={(e) => setServiceName(e.target.value)}
                      />
                      <div className="absolute right-5 top-5">
                        <img src={serviceIcon} className="h-5 w-5" alt="" />
                      </div>
                    </div>
                    <div className="relative">
                      <input
                        className="border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 rounded-md focus:outline-none focus:border-blue-300"
                        type="number"
                        value={servicePrice}
                        placeholder="Service Price"
                        onChange={(e) => setServicePrice(e.target.value)}
                      />
                      <div className="absolute right-5 top-5">
                        <img src={priceIcon} className="h-5 w-5" alt="" />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Estimated time (min)
                      </label>
                      <input
                        className="border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 rounded-md focus:outline-none focus:border-blue-300"
                        type="number"
                        value={estimatedTime}
                        step={15}
                        placeholder="Available Slots"
                        onChange={(e) =>
                          setEstimatedTime(
                            e.target.value >= 15 ? e.target.value : 15
                          )
                        }
                      />
                    </div>
                    <div className="relative">
                      <div className="flex justify-between">
                        <label className="block text-sm font-medium text-gray-700">
                          Enable for Booking
                        </label>
                        <input
                          type="checkbox"
                          checked={enableBooking}
                          onChange={(e) => setEnableBooking(e.target.checked)}
                          className="mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        AvailAble Slots
                      </label>
                      <div className="relative">
                        <input
                          className="border-[1px] w-full h-[50px] mt-1 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 rounded-md focus:outline-none focus:border-blue-300"
                          type="number"
                          value={availableSlots}
                          // step={15}
                          placeholder="Available Slots"
                          onChange={(e) =>
                            setAvailableSlots(
                              e.target.value >= 1 ? e.target.value : 1
                            )
                          }
                        />
                        <div className="absolute right-5 top-5">
                          <IoTime size={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center py-5">
                    <PrimaryButton
                      width="150px"
                      title={isEditMode ? "Update Service" : "Add Service"} // Update button title based on mode
                      onClick={handleAddOrEditService}
                      loading={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};
