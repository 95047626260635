// import { DoughnutChart } from "../index";
import { DoughnutChart } from "./BarChart";

const PieCharts = ({ data }) => {
  const chartData = {
    labels: data?.map((item) => item.day),
    datasets: [
      {
        label: "Appointments",
        data: data?.reduce((sum, item) => sum + item.appointments, 0),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Cancelled",
        data: data?.reduce((sum, item) => sum + item.cancelled, 0),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },

      {
        label: "Accepted",
        data: data?.reduce((sum, item) => sum + item.accepted, 0),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };



  return (
    <div className="">
      <section className="">
        <div className="relative h-auto">
          <DoughnutChart
            labels={["Accepted Orders", "Cancelled Orders"]}
            data={[chartData?.datasets[2]?.data, chartData?.datasets[1]?.data]}
            backgroundColor={[`#E3E3E3 `, "#252525"]}
            offset={[0, 80]}
          />

          <div className="absolute bottom-[43%] left-[24%]">
            <div className="p-2 font-semibold text-2xl flex flex-col justify-center items-center m-auto">
              <div>
                {chartData?.datasets[0]?.data + chartData?.datasets[1]?.data}
              </div>
              <div className="text-lg font-medium">Orders</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PieCharts;
