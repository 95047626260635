import { Dialog } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
import UsedCarCard from "../utils/card/usedCars/usedCarCard";
import ImageUploadButton from "../utils/buttons/imageUploadButton";
import { icons8crossFilled } from "../../assets";
import InputField from "../form/input";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsedCars,
  addUsedCars,
  updateUsedCars,
  deleteUsedCars,
} from "../../redux/usedCarsThunk";
import { toast } from "react-toastify";
import { InputFields } from "../InputField/InputFIelds";
import { validateFileSize } from "../../utility/ImageValidation";
import { ScreenLoader } from "../ScreenLoader/ScreenLoader";
import { setUsedCarSelected } from "../../redux/searchSlice";
import NoDataFound from "../NoDataFound/NoDataFound";
import axiosInstance from "../../utility/axiosInstance";
import { SlotsCreater } from "./SlotsCreater";

import { carData } from "../../assets/2020";

export const UsedCars = () => {
  const dispatch = useDispatch();

  const initialFormData = {
    front: "",
    back: "",
    left: "",
    right: "",
    overview: {
      carName: "",
      carModel: "",
      engineType: "",
      drivenKm: "",
      makeYear: "",
      registrationYear: "",
      transmission: "",
      numberOfOwners: "",
      insuranceValidity: "",
      insuranceType: "",
      location: "",
      price: "",
      testDriveAvailable: false,
      emiAvailable: false,
    },
    keySpecs: {
      fuelType: "",
      engine: "",
      maxPower: "",
      maxPowerRPM: "",
      mileage: "",
      seatingCapacity: "",
    },
    offers: [{ description: "" }],
    colours: [{ colour: "" }],
  };

  const { list: usedCars, loading } = useSelector((state) => state.usedCars);
  const searchTabs = useSelector((state) => state.search);

  const [carData, setCarData] = useState([]);

  const [addUsedCarDialogOpen, setAddUsedCarDialogOpen] = useState(false);
  const [editUsedCarDialogOpen, setEditUsedCarDialogOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedUsedCar, setSelectedUsedCar] = useState(null);
  const [errors, setErrors] = useState({});

  const [isUnique, setIsUnique] = useState({
    unique: false,
    sameUser: false,
  });
  const [error, setError] = useState("");

  const imageRefs = {
    front: useRef(null),
    back: useRef(null),
    left: useRef(null),
    right: useRef(null),
  };

  useEffect(() => {
    dispatch(setUsedCarSelected({ isActive: true, searchText: "" }));
    return () => {
      dispatch(setUsedCarSelected({ isActive: false, searchText: "" }));
    };
  }, []);

  useEffect(() => {
    const uniqueMakes = [...new Set(carData.map((car) => car.make))];
    setCarData(uniqueMakes);
  }, []);

  useEffect(() => {
    if (usedCars?.length == 0) {
      dispatch(fetchUsedCars());
    }
  }, [dispatch]);

  useEffect(() => {
    if (usedCars) {
      const obj = {};
      usedCars?.map((ele) => {
        obj["id"] = ele?._id;
        obj["front"] = ele?.images?.front;
        obj["back"] = ele?.images?.back;
        obj["left"] = ele?.images?.left;
        obj["right"] = ele?.images?.right;
        obj["overview"] = ele?.overview;
        obj["vin"] = ele?.vin;
        obj["isPublic"] = ele?.isPublic;
      });
      setFormData(obj);
    }
  }, [usedCars]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const parts = name.split(".");

    if (parts.length > 1) {
      setFormData((prev) => ({
        ...prev,
        [parts[0]]: {
          ...prev[parts[0]],
          [parts[1]]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      if (name === "overview.insuranceValidity") {
        const formattedValue = new Date(value)?.toISOString()?.split("T")[0];
        setFormData({
          ...formData,
          [name]: formattedValue,
        });
      } else {
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
      }
    }
  };

  const handleEditUsedCars = (car) => {
    if (car) {
      const obj = {};
      const { front, back, left, right } = car?.images;
      const { overview } = car;
      const { keySpecs } = car;
      const { vin } = car;
      const { isPublic } = car;

      obj.front = front;
      obj.back = back;
      obj.left = left;
      obj.right = right;
      obj.overview = overview;
      obj.keySpecs = keySpecs;
      obj.vin = vin;
      obj.isPublic = isPublic;

      setFormData(obj);
    }

    setSelectedUsedCar(car);
    setEditUsedCarDialogOpen(true);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.vin) {
      newErrors.vin = "Vin number is required.";
    }

    if (formData.vin.length > 17 || formData.vin.length < 17 || error) {
      newErrors.vin = "Vin number should be 17 digits only";
      if (error) {
        newErrors.vin = error;
      }
    }

    if (!formData.overview.carName.trim())
      newErrors.carName = "Car Name is required.";
    if (!formData.overview.price.trim() || isNaN(formData.overview.price))
      newErrors.price = "Valid Price is required.";
    if (
      !formData.keySpecs.seatingCapacity ||
      isNaN(formData.keySpecs.seatingCapacity)
    )
      newErrors.seatingCapacity = "Valid Seating Capacity is required.";

    const images = [
      formData.front,
      formData.back,
      formData.left,
      formData.right,
    ].filter(Boolean);
    if (images.length === 0)
      newErrors.images = "Please upload at least one image.";

    setErrors(newErrors);

    Object.keys(newErrors).forEach((key) => {
      toast.error(newErrors[key]);
    });

    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    setFormData(initialFormData);
    Object.values(imageRefs).forEach((ref) => {
      if (ref && ref.current && typeof ref.current.clear === "function") {
        ref.current.clear();
      } else {
        console.warn("clear method not found on ref", ref);
      }
    });
  };

  const handleUpdateUsedCar = () => {
    if (!validateForm()) return;

    const formData2 = new FormData();

    const fileKeys = ["front", "back", "left", "right"];

    for (const key of fileKeys) {
      const file = formData[key];
      if (file) {
        if (!validateFileSize(file, key)) return;
        formData2.append(key, file);
      }
    }

    if (formData?.vin) {
      formData2.append("vin", JSON.stringify(formData.vin));
    }

    if (formData?.overview) {
      formData2.append("overview", JSON.stringify(formData.overview));
    }
    if (formData.keySpecs) {
      formData2.append(`keySpecs`, JSON.stringify(formData.keySpecs));
    }

    formData2.append("isPublic", JSON.stringify(formData.isPublic));

    dispatch(
      updateUsedCars({
        id: selectedUsedCar._id,
        updatedUsedCars: formData2,
      })
    ).then(() => {
      setEditUsedCarDialogOpen(false);
      resetForm();
    });
  };

  const handleAddUsedCarConfirm = () => {
    if (!validateForm()) return;

    const formData2 = new FormData();

    const fileKeys = ["front", "back", "left", "right"];

    for (const key of fileKeys) {
      const file = formData[key];
      if (file) {
        if (!validateFileSize(file, key)) return;
        formData2.append(key, file);
      }
    }

    if (formData?.vin) {
      formData2.append("vin", JSON.stringify(formData.vin));
    }

    if (formData?.overview) {
      formData2.append("overview", JSON.stringify(formData.overview));
    }
    if (formData.keySpecs) {
      formData2.append(`keySpecs`, JSON.stringify(formData.keySpecs));
    }

    formData2.append("isPublic", JSON.stringify(formData.isPublic));

    dispatch(addUsedCars(formData2)).then(() => {
      setAddUsedCarDialogOpen(false);
      resetForm();
    });
  };

  const handleOffersChange = (e, index) => {
    const { name, value } = e.target;
    const updatedOffers = [...formData.offers];
    updatedOffers[index] = { ...updatedOffers[index], [name]: value };
    setFormData({ ...formData, offers: updatedOffers });
  };

  const handleColoursChange = (e, index) => {
    const { name, value } = e.target;
    const updatedColours = [...formData.colours];
    updatedColours[index] = { ...updatedColours[index], [name]: value };
    setFormData({ ...formData, colours: updatedColours });
  };

  const handleDeleteUsedCars = (id) => {
    dispatch(deleteUsedCars(id));
  };

  const filteredUsedCars = usedCars.filter((car) =>
    car?.overview?.carName
      ?.toLowerCase()
      .includes(searchTabs?.searchText.toLowerCase())
  );

  useEffect(() => {
    const fetchVin = async () => {
      if (formData?.vin?.length == 17) {
        try {
          const response = await axiosInstance.get(
            `usedCars/check-vin/${formData?.vin}`
          );
          setIsUnique({
            unique: response.data.isUnique,
            sameUser: response.data.sameUser,
          });
          setError("");
        } catch (error) {
          setError("Failed to validate VIN");
        }
      } else {
        setIsUnique(null);
      }
    };

    fetchVin();
  }, [formData?.vin]);

  return (
    <>
      <div>
        <div className="h-[calc(100vh-120px)] overflow-y-auto">
          {loading && (
            <div className="flex justify-center h-[calc(100vh-120px)] items-center">
              <ScreenLoader />
            </div>
          )}

          <div className="flex px-5 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
            <p className="text-black text-2xl">All Used Cars</p>
            <PrimaryButton
              onClick={() => {
                setAddUsedCarDialogOpen(true);
                resetForm();
              }}
              title={"Add Cars"}
            />
          </div>

          {filteredUsedCars?.length == 0 && (
            <div className="h-[100%]">
              {<NoDataFound message={"No Used cars found"} />}
            </div>
          )}

          {/* {filteredUsedCars?.length > 0 && (
            <div className="flex px-5 py-2 h-[80px] justify-between items-center border-b-[2px] border-lightBorderColor">
              <p className="text-black text-2xl">All Used Cars</p>
              <PrimaryButton
                onClick={() => {
                  setAddUsedCarDialogOpen(true);
                  resetForm();
                }}
                title={"Add Cars"}
              />
            </div>
          )} */}

          <div
            className={`flex p-5 w-full ${
              filteredUsedCars?.length == 0 && "hidden"
            }`}
          >
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-10">
              {filteredUsedCars?.map((item) => (
                <UsedCarCard
                  key={item._id}
                  item={item}
                  onEdit={() => handleEditUsedCars(item)}
                  onDelete={() => handleDeleteUsedCars(item._id)}
                  type={"usedCars"}
                />
              ))}
            </div>
          </div>

          <Dialog
            open={addUsedCarDialogOpen}
            onClose={() => setAddUsedCarDialogOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4 ">
              <Dialog.Panel className="w-full max-w-5xl rounded bg-white p-4 max-h-[100vh]">
                <div className="pb-2">
                  <img
                    src={icons8crossFilled}
                    onClick={() => setAddUsedCarDialogOpen(false)}
                    className="w-8 h-8 cursor-pointer ml-auto"
                  />
                </div>

                <div className="overflow-auto no-scrollbar w-full max-h-[75vh] p-1">
                  <div class="flex items-center my-4">
                    <hr class="flex-grow border-t border-muted border-black" />
                    <span class="mx-4 text-muted-foreground font-semibold text-xl">
                      Upload Images
                    </span>
                    <hr class="flex-grow border-t border-muted border-black" />
                  </div>

                  <div className="grid grid-cols-4 gap-2">
                    <ImageUploadButton
                      stateData={formData?.front}
                      setStateData={(value) =>
                        setFormData({ ...formData, front: value })
                      }
                      refData={imageRefs.front}
                      view={"Front View"}
                    />
                    <ImageUploadButton
                      stateData={formData.back}
                      setStateData={(value) =>
                        setFormData({ ...formData, back: value })
                      }
                      refData={imageRefs.back}
                      view={"Back View"}
                    />
                    <ImageUploadButton
                      stateData={formData.left}
                      setStateData={(value) =>
                        setFormData({ ...formData, left: value })
                      }
                      refData={imageRefs.left}
                      view={"Left View"}
                    />
                    <ImageUploadButton
                      stateData={formData.right}
                      setStateData={(value) =>
                        setFormData({ ...formData, right: value })
                      }
                      refData={imageRefs.right}
                      view={"Right View"}
                    />
                  </div>

                  <div className="">
                    <div className="">
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Overview
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <div>
                          <InputFields
                            type="text"
                            title="Vin Number"
                            placeholder="Vin Number"
                            value={formData?.vin}
                            name="vin"
                            onChange={handleInputChange}
                          />

                          {isUnique?.unique === false && (
                            <p className="mt-2" style={{ color: "red" }}>
                              This VIN is already registered.
                            </p>
                          )}
                          {isUnique?.unique === true && (
                            <p className="mt-2" style={{ color: "green" }}>
                              This VIN is available.
                            </p>
                          )}
                          {error && <p style={{ color: "red" }}>{error}</p>}
                        </div>

                        <InputFields
                          type="text"
                          title="Car Name"
                          placeholder="Car Name"
                          value={formData?.overview?.carName}
                          name="overview.carName"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Price"
                          placeholder="Price"
                          value={formData?.overview?.price}
                          name="overview.price"
                          onChange={handleInputChange}
                        />
                        {/* Add more fields as needed */}
                        <InputFields
                          type="text"
                          title="Engine Type"
                          placeholder="Engine Type"
                          value={formData?.overview?.engineType}
                          name="overview.engineType"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Driven KM"
                          placeholder="Driven KM"
                          value={formData?.overview?.drivenKm}
                          name="overview.drivenKm"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Make Year"
                          placeholder="Make Year"
                          value={formData?.overview?.makeYear}
                          name="overview.makeYear"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Registration Year"
                          placeholder="Registration Year"
                          value={formData?.overview?.registrationYear}
                          name="overview.registrationYear"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Transmission"
                          placeholder="Transmission"
                          value={formData?.overview?.transmission}
                          name="overview.transmission"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Number of Owners"
                          placeholder="Number of Owners"
                          value={formData?.overview?.numberOfOwners}
                          name="overview.numberOfOwners"
                          onChange={handleInputChange}
                        />

                        <InputFields
                          type="text"
                          title="Insurance Type"
                          placeholder="Insurance Type"
                          value={formData?.overview?.insuranceType}
                          name="overview.insuranceType"
                          onChange={handleInputChange}
                        />

                        <InputFields
                          type="text"
                          title="Car Model"
                          placeholder="Car Model"
                          value={formData?.overview?.carModel}
                          name="overview.carModel"
                          onChange={handleInputChange}
                        />

                        <InputFields
                          type="text"
                          title="Location"
                          placeholder="Location"
                          value={formData?.overview?.location}
                          name="overview.location"
                          onChange={handleInputChange}
                        />

                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Listing Type
                          </label>

                          <div className="flex flex-row mt-1">
                            <select
                              id="isPublic"
                              name="isPublic"
                              value={formData?.isPublic}
                              // onChange={handleInputChange}
                              //   className="py-4 px-4 border rounded"
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            >
                              <option value={true}>Public</option>
                              <option value={false}>Private</option>
                            </select>
                          </div>
                        </div>

                        <div className="">
                          <InputFields
                            type="date"
                            title="Insurance Validity"
                            placeholder="Insurance Validity"
                            value={
                              formData?.overview?.insuranceValidity.split(
                                "T"
                              )[0]
                            }
                            name="overview.insuranceValidity"
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Key Specs
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <InputFields
                          type="text"
                          title="Engine"
                          placeholder="Engine"
                          value={formData?.keySpecs?.engine}
                          name="keySpecs.engine"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Max Power"
                          placeholder="Max Power"
                          value={formData?.keySpecs?.maxPower}
                          name="keySpecs.maxPower"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Max Power RPM"
                          placeholder="Max Power RPM"
                          value={formData?.keySpecs?.maxPowerRPM}
                          name="keySpecs.maxPowerRPM"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Mileage"
                          placeholder="Mileage"
                          value={formData?.keySpecs?.mileage}
                          name="keySpecs.mileage"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Seating Capacity"
                          placeholder="Seating Capacity"
                          value={formData?.keySpecs?.seatingCapacity}
                          name="keySpecs.seatingCapacity"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Fuel Type"
                          placeholder="Fuel Type"
                          value={formData?.keySpecs?.fuelType}
                          name="keySpecs.fuelType"
                          onChange={handleInputChange}
                        />
                      </div>
                      {/* <SlotsCreater /> */}
                    </div>
                  </div>

                  <div className="flex justify-center mt-5">
                    <PrimaryButton
                      onClick={handleAddUsedCarConfirm}
                      title="Add Used Car"
                      loading={loading}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>

          <Dialog
            open={editUsedCarDialogOpen}
            onClose={() => setEditUsedCarDialogOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30 flex items-center justify-center p-4 ">
              <Dialog.Panel className="w-full max-w-5xl rounded bg-white p-4 max-h-[100vh] overflow-auto no-scrollbar">
                <div className="pb-2">
                  <img
                    src={icons8crossFilled}
                    onClick={() => setEditUsedCarDialogOpen(false)}
                    className="w-8 h-8 cursor-pointer ml-auto"
                  />
                </div>

                <div className="overflow-auto no-scrollbar w-full max-h-[75vh] p-1">
                  <div class="flex items-center my-4">
                    <hr class="flex-grow border-t border-muted border-black" />
                    <span class="mx-4 text-muted-foreground font-semibold text-xl">
                      Upload Images
                    </span>
                    <hr class="flex-grow border-t border-muted border-black" />
                  </div>

                  <div className="grid grid-cols-4 gap-2">
                    <ImageUploadButton
                      stateData={formData.front}
                      setStateData={(value) =>
                        setFormData({ ...formData, front: value })
                      }
                      refData={imageRefs.front}
                      view={"Front View"}
                    />
                    <ImageUploadButton
                      stateData={formData.back}
                      setStateData={(value) =>
                        setFormData({ ...formData, back: value })
                      }
                      refData={imageRefs.back}
                      view={"Back View"}
                    />
                    <ImageUploadButton
                      stateData={formData.left}
                      setStateData={(value) =>
                        setFormData({ ...formData, left: value })
                      }
                      refData={imageRefs.left}
                      view={"Left View"}
                    />
                    <ImageUploadButton
                      stateData={formData.right}
                      setStateData={(value) =>
                        setFormData({ ...formData, right: value })
                      }
                      refData={imageRefs.right}
                      view={"Right View"}
                    />
                  </div>

                  <div className="">
                    <div className="">
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Overview
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <div>
                          <InputFields
                            type="text"
                            title="Vin Number"
                            placeholder="Vin Number"
                            value={formData?.vin}
                            name="vin"
                            disabled={true}
                            onChange={handleInputChange}
                          />

                          {/* { (isUnique?.unique == false || isUnique?.sameUser == true) && (
                          <p className="mt-2" style={{ color: "red" }}>
                            This VIN is already registered by you.
                          </p>
                        )}

                        {isUnique?.unique === true && (
                          <p className="mt-2" style={{ color: "green" }}>
                            This VIN is available.
                          </p>
                        )} */}

                          {error && <p style={{ color: "red" }}>{error}</p>}
                        </div>

                        <InputFields
                          type="text"
                          title="Car Name"
                          placeholder="Car Name"
                          value={formData?.overview?.carName}
                          name="overview.carName"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Price"
                          placeholder="Price"
                          value={formData?.overview?.price}
                          name="overview.price"
                          onChange={handleInputChange}
                        />
                        {/* Add more fields as needed */}
                        <InputFields
                          type="text"
                          title="Engine Type"
                          placeholder="Engine Type"
                          value={formData?.overview?.engineType}
                          name="overview.engineType"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Driven KM"
                          placeholder="Driven KM"
                          value={formData?.overview?.drivenKm}
                          name="overview.drivenKm"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Make Year"
                          placeholder="Make Year"
                          value={formData?.overview?.makeYear}
                          name="overview.makeYear"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Registration Year"
                          placeholder="Registration Year"
                          value={formData?.overview?.registrationYear}
                          name="overview.registrationYear"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Transmission"
                          placeholder="Transmission"
                          value={formData?.overview?.transmission}
                          name="overview.transmission"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Number of Owners"
                          placeholder="Number of Owners"
                          value={formData?.overview?.numberOfOwners}
                          name="overview.numberOfOwners"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="date"
                          title="Insurance Validity"
                          placeholder="Insurance Validity"
                          value={
                            formData?.overview?.insuranceValidity.split("T")[0]
                          }
                          name="overview.insuranceValidity"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Insurance Type"
                          placeholder="Insurance Type"
                          value={formData?.overview?.insuranceType}
                          name="overview.insuranceType"
                          onChange={handleInputChange}
                        />

                        <InputFields
                          type="text"
                          title="Insurance Type"
                          placeholder="Car Model"
                          value={formData?.overview?.carModel}
                          name="overview.carModel"
                          onChange={handleInputChange}
                        />

                        <InputFields
                          type="text"
                          title="Location"
                          placeholder="Location"
                          value={formData?.overview?.location}
                          name="overview.location"
                          onChange={handleInputChange}
                        />

                        <div>
                          <label className="mb-1 text-sm font-medium">
                            Listing Type
                          </label>

                          <div className="flex flex-row mt-1">
                            <select
                              id="isPublic"
                              name="isPublic"
                              value={formData?.isPublic}
                              onChange={handleInputChange}
                              //   className="py-4 px-4 border rounded"
                              className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
                            >
                              <option value={true}>Public</option>
                              <option value={false}>Private</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="flex items-center my-4">
                        <hr class="flex-grow border-t border-muted border-black" />
                        <span class="mx-4 text-muted-foreground font-semibold text-xl">
                          Key Specs
                        </span>
                        <hr class="flex-grow border-t border-muted border-black" />
                      </div>

                      <div className="grid grid-cols-3 gap-5 mt-5">
                        <InputFields
                          type="text"
                          title="Engine"
                          placeholder="Engine"
                          value={formData?.keySpecs?.engine}
                          name="keySpecs.engine"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Max Power"
                          placeholder="Max Power"
                          value={formData?.keySpecs?.maxPower}
                          name="keySpecs.maxPower"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Max Power RPM"
                          placeholder="Max Power RPM"
                          value={formData?.keySpecs?.maxPowerRPM}
                          name="keySpecs.maxPowerRPM"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Mileage"
                          placeholder="Mileage"
                          value={formData?.keySpecs?.mileage}
                          name="keySpecs.mileage"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="number"
                          title="Seating Capacity"
                          placeholder="Seating Capacity"
                          value={formData?.keySpecs?.seatingCapacity}
                          name="keySpecs.seatingCapacity"
                          onChange={handleInputChange}
                        />
                        <InputFields
                          type="text"
                          title="Fuel Type"
                          placeholder="Fuel Type"
                          value={formData?.keySpecs?.fuelType}
                          name="keySpecs.fuelType"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    {/* <div>
                    <h1 className="text-center">Offers</h1>

                    {formData?.offers?.map((offer, index) => (
                      <div key={index} className="flex gap-2">
                        <InputField
                          type="text"
                          title={`Offer ${index + 1}`}
                          placeholder="Offer Description"
                          value={offer?.description}
                          name="description"
                          onChange={(e) => handleOffersChange(e, index)}
                        />
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        setFormData({
                          ...formData,
                          offers: [...formData?.offers, { description: "" }],
                        })
                      }
                      className="mt-2 text-blue-600"
                    >
                      Add Offer
                    </button>
                  </div> */}
                    {/* 
                  <div>
                    <h1>Colours</h1>

                    <div>
                      {formData?.colours?.map((colour, index) => (
                        <div key={index} className="flex gap-2">
                          <InputField
                            type="text"
                            title={`Colour ${index + 1}`}
                            placeholder="Colour"
                            value={colour?.colour}
                            name="colour"
                            onChange={(e) => handleColoursChange(e, index)}
                          />
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() =>
                          setFormData({
                            ...formData,
                            colours: [...formData?.colours, { colour: "" }],
                          })
                        }
                        className="mt-2 text-blue-600"
                      >
                        Add Colour
                      </button>
                    </div>
                  </div> */}
                  </div>

                  <div className="flex justify-center mt-5">
                    <PrimaryButton
                      onClick={handleUpdateUsedCar}
                      title="Update Details"
                      loading={loading}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
};
