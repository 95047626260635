// @ts-nocheck

import { useEffect, useRef, useState } from "react";
import SecondaryButton from "../../buttons/secondaryButton";
import { deleteIcon, editIcon } from "../../../../assets";
import { Dialog } from "@headlessui/react";
import ImageUploadButton from "../../buttons/imageUploadButton";
import InputField from "../../../form/input";
import PrimaryButton from "../../buttons/primaryButton";
import { baseURL } from "../../../../utility/axiosInstance";
import { icons } from "../../../../utility/icons";

const UsedCarCard = ({ item, onEdit, onDelete, type = null }) => {
  // const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const [addSparePart, setAddSparePart] = useState(false);
  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");
  const [thirdImage, setThirdImage] = useState("");
  const [productName, setProductName] = useState(item?.name);
  const [price, setPrice] = useState("");
  const [soldBy, setSoldBy] = useState("");
  const [brand, setBrand] = useState("");
  const [size, setSize] = useState("");
  const [modal, setModal] = useState("");
  const [sectionWidth, setSectionWidth] = useState("");

  const firstImageRef = useRef(null);
  const secondImageRef = useRef(null);
  const thirdImageRef = useRef(null);

  const [imageUrl, setImageUrl] = useState(null);

  const url = baseURL.replace(/\/api$/, "");

  useEffect(() => {
    if (type == "usedCars") {
      setImageUrl(`${url}/${item?.images?.front}`);
      console.log(imageUrl, "imageUrl");
    } else {
      setImageUrl(`${url}/${item?.firstImage?.substring(39)}`);
    }
  }, [type, item]);

  console.log(imageUrl, "imageUrl");

  return (
    <div className="flex flex-col  rounded-[15px] shadow-md">
      <div className="">
        <img
          src={imageUrl || ""}
          className="h-72 w-80 bg-cover rounded-[15px]"
          alt="logo"
        />
      </div>
      <div className="">
        <p className="flex justify-center my-4 text-base font-semibold">
          {item?.carName || item?.productName || item?.overview?.carName}
        </p>
      </div>
      <div className="flex justify-between my-2 pb-5 px-2 gap-2 xl:px-5">
        <PrimaryButton
          title={"Edit"}
          onClick={() => {
            console.log("edit button clicked");
            onEdit(item);
          }}
          textColor={"white"}
          bgColor={"#990000"}
          // beforeIcon={editIcon}
          icon={icons?.editIcon}
        />
        <PrimaryButton
          title={"Delete"}
          onClick={() => {
            console.log("delete button clicked");
            onDelete(item);
          }}
          textColor={"black"}
          bgColor={" gray-300"}
          icon={icons?.deleteIcon}
          // beforeIcon={deleteIcon}
        />
      </div>
    </div>
  );
};

export default UsedCarCard;
