import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceRequestSelected: false,
  orderSelected: false,
  usedCarSelected: false,
  serviceOptionSelected: false,
  sparePartsOptionSelected: false,
  offersOptionSelected: false,
  reminderOptionSelected: false,
  customersOptionSelected: false,
  searchText: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setServiceRequestSelected: (state, action) => {
      const { isActive, searchText } = action.payload;
      state.serviceRequestSelected = isActive;
      state.searchText = searchText;
    },
    setOrderSelected: (state, action) => {
      const { isActive, searchText } = action.payload;
      state.orderSelected = isActive;
      state.searchText = searchText;
    },
    setUsedCarSelected: (state, action) => {
      const { isActive, searchText } = action.payload;
      state.usedCarSelected = isActive;
      state.searchText = searchText;
    },
    setServiceOptionSelected: (state, action) => {
      const { isActive, searchText } = action.payload;
      state.serviceOptionSelected = isActive;
      state.searchText = searchText;
    },
    setSparePartsOptionSelected: (state, action) => {
      const { isActive, searchText } = action.payload;
      state.sparePartsOptionSelected = isActive;
      state.searchText = searchText;
    },
    setOffersOptionSelected: (state, action) => {
      const { isActive, searchText } = action.payload;
      state.offersOptionSelected = isActive;
      state.searchText = searchText;
    },
    setReminderOptionSelected: (state, action) => {
      const { isActive, searchText } = action.payload;
      state.reminderOptionSelected = isActive;
      state.searchText = searchText;
    },
    setCustomersOptionSelected: (state, action) => {
      const { isActive, searchText } = action.payload;
      state.customersOptionSelected = isActive;
      state.searchText = searchText;
    },
  },
});

export const {
  setOrderSelected,
  setServiceRequestSelected,
  setUsedCarSelected,
  setServiceOptionSelected,
  setCustomersOptionSelected,
  setOffersOptionSelected,
  setReminderOptionSelected,
  setSparePartsOptionSelected
} = searchSlice.actions;

export default searchSlice.reducer;
