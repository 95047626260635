import { createAsyncThunk } from "@reduxjs/toolkit";
import { customers } from "../utility/api";
import { login, setLoading } from "./authSlice";
import axiosInstance, { notifyError } from "../utility/axiosInstance";

export const fetchCurrentUser = createAsyncThunk(
  "currentUser/fetchCurrentUser",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(customers.getUserMe);
      dispatch(login(response.data));
    } catch (error) {
      notifyError(`Failed to fetch notifications:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
