/* eslint-disable jsx-a11y/alt-text */
import { Dialog } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { icons8crossFilled } from "../../../assets";

import { Steps } from "rsuite";
import { formatString, serviceRequests } from "../../../utility/api";
import { ServiceBookingCard } from "./ServiceBookingCard";
import axiosInstance, { notifySuccess } from "../../../utility/axiosInstance";
import PaymentCard from "./PaymentCard";
import CloseCard from "./CloseCard";

function BookingModal({
  currentStatus,
  setBookModal,
  isBookModal,
  serviceStatus,
  setServiceStatus,
  data,
  setData,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepInfo, setCurrentStepInfo] = useState(0);
  const handleStepClick = (index) => {
    setCurrentStepInfo(index);
  };

  const filterData = data.find(({ _id }) => serviceStatus._id === _id);
  useEffect(() => {
    if (filterData) {
      const { status, paymentStatus } = filterData;
      let bookingStatus = 0;
      switch (status) {
        case "accepted":
          bookingStatus = 1;
          break;
        case "rejected":
          break;
        case "pending":
          bookingStatus = 0;
          break;
        case "completed":
          bookingStatus = 2;
          break;
        default:
          bookingStatus = 0;
          break;
      }
      setCurrentStep(bookingStatus);
      setCurrentStepInfo(bookingStatus);
    }
  }, [filterData]);

  console.log(filterData);
  const getStatusDescription = (type) => {
    try {
      //   const historyEntry = serviceStatus?.booking?.statusHistory?.find(
      //     (entry) => entry.status === status
      //   );
      let state = "Not yet completed";

      switch (type) {
        case "requested":
          state =
            filterData?.status === "accepted" &&
            (filterData?.requestedAt.status === "scheduled" ||
              filterData?.requestedAt.status === "rescheduleConfirmed")
              ? "Completed"
              : "Not yet completed";
          break;
        case "accepted":
          state =
            filterData?.status === "scheduled" ||
            filterData?.status === "rescheduleConfirmed"
              ? "Completed"
              : "Not yet completed";
          break;
        case "payment":
          state =
            filterData?.paymentStatus === "completed"
              ? "Completed"
              : filterData?.paymentStatus === "requested"
              ? "Requested"
              : "Not yet completed";
          break;
        case "complete":
          state =
            filterData?.status === "completed"
              ? "Completed"
              : "Not yet completed";
          break;
        case "rejected":
          state = "rejected";
          break;
        default:
          state = "Not yet completed";
      }
      return state;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={isBookModal}
      onClose={() => setBookModal(true)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full flex justify-center items-center rounded">
          <div className="min-h-[55vh] w-[700px] rounded-md relative bg-[#F5F5F5] p-4">
            <div>
              <div className="p-2">
                <img
                  src={icons8crossFilled}
                  onClick={() => {
                    setBookModal(false);
                  }}
                  className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                />
              </div>
            </div>
            <div className="mt-0">
              {/* data goes here */}
              <div className="">
                <h1 className="text-2xl p-2">Car booking status</h1>
              </div>
              <div className="">
                <div className="flex gap-8 justify-around items-center">
                  <div className="max-h-[65vh] overflow-auto no-scrollbar p-2">
                    <Steps
                      current={currentStep}
                      vertical
                      className="custom-step rs-theme-light"
                    >
                      <Steps.Item
                        className="cursor-pointer"
                        onClick={() => handleStepClick(0)}
                        title="Request"
                        description={getStatusDescription("requested")}
                        status={
                          currentStep !== 0
                            ? "finish"
                            : filterData?.status === "rejected"
                            ? "error"
                            : "process"
                        }
                      />

                      {/* <Steps.Item
                        className="cursor-pointer"
                        onClick={() => handleStepClick(1)}
                        title="Acceptance"
                        description={getStatusDescription("accepted")}
                        status={
                          getStatusDescription("Accepted").status == "Accepted"
                        }
                      /> */}

                      <Steps.Item
                        className="cursor-pointer"
                        onClick={() => handleStepClick(1)}
                        title="Payment"
                        description={getStatusDescription("payment")}
                        status={
                          filterData?.payment.paymentStatus === "completed"
                            ? "finish"
                            : filterData?.status === "rejected"
                            ? "error"
                            : filterData?.status === "accepted"
                            ? "process"
                            : "wait"
                        }
                      />

                      <Steps.Item
                        className="cursor-pointer"
                        onClick={() => handleStepClick(2)}
                        title="Close Service"
                        description={getStatusDescription("complete")}
                        status={
                          filterData?.status === "rejected"
                            ? "error"
                            : filterData?.status === "completed"
                            ? "finish"
                            : "wait"
                        }
                      />
                    </Steps>
                  </div>
                  <div>
                    <div className="w-full bg-[#EDEDEDED]">
                      {/* Dynamic rendering of the update card based on the current step */}
                      {currentStepInfo === 0 && (
                        <div>
                          <h2 className="text-center text-lg p-2">
                            Acceptance
                          </h2>
                          <ServiceBookingCard
                            setBookModal={setBookModal}
                            currentStepInfo={currentStepInfo}
                            setCurrentStepInfo={setCurrentStepInfo}
                            data={filterData}
                            setData={setData}
                            setServiceStatus={setServiceStatus}
                          />
                        </div>
                      )}
                      {currentStepInfo === 1 && (
                        <div>
                          <h2 className="text-center text-lg p-2">Payment</h2>
                          <PaymentCard setData={setData} data={filterData} />
                        </div>
                      )}
                      {currentStepInfo === 2 && (
                        <div>
                          <h2 className="text-center text-lg p-2">Payment</h2>
                          <CloseCard data={filterData} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default BookingModal;
