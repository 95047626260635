import React, { useContext, useEffect, useState } from "react";
import { ServiceSettingContext } from "../ServiceSettingIndex";
import PrimaryButton from "../../../utils/buttons/primaryButton";
import { useSelector } from "react-redux";

const ServiceListComponent = () => {
  const { dateValue, services, setServices } = useContext(
    ServiceSettingContext
  );
  const { list, loading, serviceSettingInfo } = useSelector(
    (state) => state?.services
  );
  const [CUState, setCUState] = useState("");

  useEffect(() => {
    const tempService = [];
    try {
      if (list) {
        const { serviceDateSettings } = serviceSettingInfo;

        const allServices = serviceDateSettings?.find(({ date }) => {
          return date === new Date(dateValue).toLocaleDateString();
        });
        
        list.forEach((element) => {
          const body ={
            serviceName: element.serviceName,
            status: false,
            service: element._id,
          }
          if (allServices) {
            const state = allServices.services.find(
              ({ service }) => service === element._id
            );
            body.status = state?.status ? state?.status : false
          }
          // console.log(body);
          tempService.push(body);
        });
      }
    } catch (error) {
      console.log(error);
    }

    setServices(tempService);
  }, [list,dateValue,serviceSettingInfo]);

  // console.log(services);

  // useEffect(() => {
  //   const tempService = [];

  //   if (serviceSettingInfo) {
  //     const { serviceDateSettings } = serviceSettingInfo;

  //     const allServices = serviceDateSettings.find(({ date }) => {
  //       return date == new Date(dateValue).toLocaleDateString();
  //     });
  //     const allServicesID = [];
  //     allServices.services.forEach(({ service }) =>
  //       allServicesID.push(service)
  //     );

  //     // console.log(allServices,services);
  //     const extraService = [];
  //     tempService.forEach((element) => {
  //       if (allServicesID.includes(element.service)) {
  //         extraService.push(element);
  //       }
  //     });
  //     // setServices(...new Set([...extraService, ...allServices.services]));
  //     console.log(...new Set([...extraService, ...allServices.services]));
  //   }

  //   // setServices(tempService);
  // }, [dateValue, serviceSettingInfo]);

  const onCheck = (checkAll = true) => {
    if (checkAll) {
      setServices((state) => state.map((item) => ({ ...item, status: true })));
    } else {
      setServices((state) => state.map((item) => ({ ...item, status: false })));
    }
  };

  return (
    <div className="flex flex-col gap-4 w-44 h-96 overflow-y-auto">
      <span className="text-lg">Day Service Setting</span>
      <span className="text-lg">
        Date: {new Date(dateValue).toDateString()}
      </span>
      <div className="relative w-40">
        <div className="flex  flex-row justify-between ">
          <label className="block text-base font-medium text-gray-700">
            Check All
          </label>
          <input
            type="radio"
            checked={CUState === "check"}
            onChange={(e) => {
              onCheck();
              setCUState((state) => (state == "check" ? "" : "check"));
            }}
          />
        </div>
        <div className="flex  flex-row justify-between ">
          <label className="block text-base font-medium text-gray-700">
            UnCheck All
          </label>
          <input
            type="radio"
            checked={CUState === "uncheck"}
            onChange={(e) => {
              onCheck(false);
              setCUState((state) => (state == "uncheck" ? "" : "uncheck"));
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 max-h-12">
        {services?.map(({ serviceName }, index) => (
          <ServiceCheckBoxComponent
            key={index}
            index={index}
            title={serviceName}
          />
        ))}
      </div>
      {/* <div className="flex justify-start py-2">
        <PrimaryButton
          width="120px"
          title={"Update"}
          // onClick={handleUpdatePKL}
          // loading={loading}
        />
      </div> */}
    </div>
  );
};

const ServiceCheckBoxComponent = ({ title, index }) => {
  const { services, setServices } = useContext(ServiceSettingContext);

  return (
    <div className="relative w-40">
      <div className="flex  flex-row justify-between ">
        <label className="block text-base font-medium text-gray-700">
          {title}
        </label>
        <input
          type="checkbox"
          checked={services[index].status}
          onChange={(e) => {
            setServices((prevState) => {
              const updatedServices = [...prevState]; // Create a copy of the services array
              updatedServices[index] = {
                ...updatedServices[index],
                status: e.target.checked, // Toggle the checkbox state
              };

              console.log("Updated service:", updatedServices[index]);

              return updatedServices; // Return the updated services array
            });
          }}
          className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
    </div>
  );
};
export default ServiceListComponent;
