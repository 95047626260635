import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    list: [],
    loading: false,
    notificationCount: 0,
  },
  reducers: {
    addNotification: (state, action) => {
      state.list.push(action.payload);
    },
    setNotifications: (state, action) => {
      state.list = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    clearNotifications: (state) => {
      state.list = [];
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  addNotification,
  setNotifications,
  clearNotifications,
  setLoading,
  setNotificationCount
} = notificationSlice.actions;

export default notificationSlice.reducer;
