import { Dialog } from "@headlessui/react";
import React, { useState } from "react";
import { icons8crossFilled } from "../../../assets";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../../../utility/axiosInstance";
import { serviceRequests } from "../../../utility/api";

const RejectionModel = ({
  isReject,
  setIsReject,
  serviceStatus,
  setServiceStatus,
  setData,
}) => {
  const [isLoading, setLoading] = useState(false);
  const handleUpdate = async (status, updatedData) => {
    try {
      console.log(updatedData);
      setLoading(true);
      const response = await axiosInstance.put(
        `${serviceRequests.updateServiceRequest}/${updatedData._id}`,
        {
          status,
          cancellationReason: updatedData?.cancellationReason ?? "",
          proposedDate: updatedData?.proposedDate ?? "",
        }
      );
      if (response.status === 200) {
        notifySuccess("Car booking request has been updated");
        console.log(response);
        if (status === "rejected") {
          setData((prevData) =>
            prevData.filter((item) =>
              updatedData._id === item?._id ? false : true
            )
          );
        } else {
          setData((prevData) =>
            prevData.map((item) =>
              updatedData._id === item?._id
                ? {
                    ...item,
                    status: updatedData.status,
                    cancellationReason: updatedData?.cancellationReason ?? "",
                    requestedAt: {
                      ...item.requestedAt,
                      proposedDate: updatedData?.proposedDate ?? "",
                    },
                  }
                : item
            )
          );
        }
        
        // setServiceStatus((state) => ({
        //   ...state,
        //   currentStatus: response.data.booking.status,
        //   cancellationReason: response.data.booking.cancellationReason,
        //   booking: response?.data.booking,
        // }));

        setIsReject(false);

        // const { bookings: allData } = await fetchCarBookings();
        // setData(allData);
        // currentStep(response.data.booking.status);
      } else {
        console.error("Error updating status", response);
      }
    } catch (error) {
      notifyError(`Error updating status ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isReject}
      onClose={() => setIsReject(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/70 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="w-full flex justify-center items-center rounded">
          <div className="max-h-[40vh] w-[400px] rounded-md relative bg-[#F5F5F5] p-4">
            <div>
              <div className="p-2">
                <img
                  src={icons8crossFilled}
                  onClick={() => {
                    setIsReject(!isReject);
                  }}
                  className="w-8 h-8 absolute flex justify-center items-center right-2 top-2 rounded-full cursor-pointer"
                />
              </div>
            </div>

            <div className="mt-0 p-2">
              {/* data goes here */}
              <div className="">
                <h1 className="">Enter your rejection reason</h1>
              </div>
              <div>
                <div className="flex justify-center items-center min-w-full">
                  <div className="w-full">
                    <textarea
                      value={serviceStatus?.cancellationReason}
                      onChange={(e) =>
                        setServiceStatus((state) => ({
                          ...state,
                          cancellationReason: e.target.value,
                        }))
                      }
                      placeholder="Enter rejection reason"
                      className="w-full border border-gray-300 rounded mt-2"
                      rows="4"
                    />
                    <button
                      disabled={isLoading}
                      type="button"
                      onClick={() => handleUpdate("rejected", serviceStatus)}
                      className="bg-[#990000] hover:bg-[#990000] text-white font-semibold py-2 px-4 w-full ml-auto rounded"
                    >
                      {isLoading ? "...." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

RejectionModel.propTypes = {};

export default RejectionModel;
