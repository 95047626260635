import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../redux/notificationSlice";
import { io } from "socket.io-client";
import { getCurrentUser } from "../utility/api";
import { fetchNotifications } from "../redux/notificationsThunk";
import { setUsedCarsRequest } from "../redux/usedCarsSlice";

const baseURL = process.env.REACT_APP_API_SOCKET;
const token = localStorage.getItem("token");

const useSocket = (onNewMessage, onNewRequest) => {
  const dispatch = useDispatch();
  const usedCarRequests = useSelector(
    (state) => state?.usedCars?.usedCarRequest
  );
  const socket = useRef(null);

  useEffect(() => {
    const initializeSocket = async () => {
      try {
        if (!socket.current) {
          const currentUser = await getCurrentUser();
          socket.current = io(baseURL, { auth: { token } });

          socket.current.on("connect", () => {
            console.log("Connected to WebSocket server");
            socket?.current?.emit("registerAdmin", currentUser.data._id);
            socket?.current?.emit("registerUser", currentUser.data._id);
          });

          socket.current.on("newServiceRequest", (notification) => {
            if (onNewRequest) {
              onNewRequest(notification);
            }
            dispatch(addNotification(notification));
            dispatch(fetchNotifications());
          });

          socket.current.on("newOrderRequest", (notification) => {
            if (onNewRequest) {
              onNewRequest(notification);
            }
            dispatch(fetchNotifications(notification));
          });

          socket.current.on("newUsedCarRequest", (notification) => {
            dispatch(setUsedCarsRequest(!usedCarRequests));
            dispatch(fetchNotifications(notification));
          });

          socket.current.on("disconnect", () => {
            console.log("Disconnected from WebSocket server");
          });

          socket.current.on("newMessageReceived", (message) => {
            if (onNewMessage) {
              onNewMessage(message);
            }
          });
        }

        return () => {
          socket.current.off("newServiceRequest");
          socket.current.off("newOrderRequest");
          socket.current.off("newMessageReceived");
          socket.current.off("newUsedCarRequest");
          socket.current.disconnect();
        };
      } catch (error) {
        console.error("Error initializing socket:", error);
      }
    };

    initializeSocket();

    return () => {
      if (socket.current) {
        socket.current.disconnect();
        socket.current = null;
      }
    };
  }, [dispatch, onNewMessage, onNewRequest]);
};

export default useSocket;
