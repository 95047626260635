import React, { useState, useEffect } from "react";
import { fetchTax } from "../../utility/api";
import InputField from "../form/input";
import SecondaryButton from "../utils/buttons/secondaryButton";
import { useSelector } from "react-redux";

export const TaxCalculator = ({
  id,
  setSelectedTaxInfo,
  selectedTaxInfo,
  data,
}) => {
  const [province, setProvince] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");

  const userData = useSelector((state) => state.user.list);

  useEffect(() => {
    try {
      setProvince(data?.map((item) => item.province));

      if (userData?.taxDetails) {
        setSelectedProvince(userData?.taxDetails.province);
        const taxInfo = data?.find(
          (item) => item.province === userData?.taxDetails.province
        );
        setSelectedTaxInfo({ ...taxInfo, ...userData?.taxDetails });
      }
    } catch (error) {}
  }, [data]);

  const handleProvinceChange = (event) => {
    const provinceName = event.target.value;try {
      
      setSelectedProvince(provinceName);
      const taxInfo = data?.find((item) => item.province === provinceName);
      setSelectedTaxInfo(taxInfo || null);
    } catch (error) {
      
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    if (selectedTaxInfo) {
      setSelectedTaxInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };

  return (
    <div className="w-[60%] max-h-[400px] m-auto">
      <label className="mb-1">Select Province: </label>
      <select
        id="province-select"
        className=" border-[1px] w-full h-[49px] mt-0 transition bg-white ease-in-out delay-150 border-[#ccc] truncate leading-[19.07px] text-gray-600 text-lg py-[8px] px-2 lg:pl-3 placeholder:text-base  rounded-md  focus:outline-none focus:border-blue-300"
        onChange={handleProvinceChange}
        value={selectedProvince}
      >
        <option value="">--Select a province--</option>
        {province?.map((prov) => (
          <option key={prov} value={prov}>
            {prov}
          </option>
        ))}
      </select>

      {selectedTaxInfo && (
        <div className="grid grid-cols-2 gap-5 mt-5 ">
          <InputField
            type="text"
            label="Type"
            value={selectedTaxInfo?.type}
            // onChange={handleInputChange}
          />

          <InputField
            type="text"
            name="rate"
            label="Rate"
            title={"Rate"}
            value={selectedTaxInfo?.rate}
            onChange={handleInputChange}
          />

          <InputField
            type="text"
            name="provincialSalesTax"
            label="Provincial Sales Tax"
            value={selectedTaxInfo?.provincialSalesTax}
            onChange={handleInputChange}
          />

          <InputField
            type="text"
            name="federalGST"
            label={"Federal GST"}
            value={selectedTaxInfo?.federalGST}
            onChange={handleInputChange}
          />
        </div>
      )}
    </div>
  );
};
