import React from 'react';

const Privacy = () => {
  return (
    <div style={styles.container}>
      <h1>Privacy Policy</h1>
      <h2>Introduction</h2>
      <p>This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our application.</p>
            
      <section style={styles.section}>
        <h2>1. GENERAL</h2>
        <p> a. The Mobile Application with the name of C4Cars ("App/C4Cars") as well as the web application hosted at 
            “c4cars.ca” is operated by Axzora Private Limited(We/Our/Us''). 
            We are committed to protecting and respecting your privacy. We do collect your personal information and 
            process your personal data in accordance with the IT Act, 2000 (21 of 2000) and other national and 
            state laws that relate to the processing of personal data. 
            Please read the following carefully to understand our views and practices regarding your personal data. 
            </p>
<p>b. Downloading, accessing, or otherwise using the App indicates that you have read this Privacy Policy and consent to its terms. If you do not consent to the terms of this Privacy Policy, do not proceed to download, access, or otherwise use the App. 
</p>
<p>c. We collect your personal information in order to provide and continually improve our products and services. 
</p>
<p>d. Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically. The last updated date can be found at the beginning of this policy. 
</p>
<p>e. All partner firms and any third-party working with or for Us, and who have access to personal information, will be expected to read and comply with this policy.
</p>
 </section>
      
      <section style={styles.section}>
        <h2>2. HOW WE COLLECT THE INFORMATION</h2>
        <p>a. From you directly and through this App: We may collect information through the App when you visit. The data we collect depends on the context of your interactions with our App. 
        </p>
        <p>b. Through business interaction: We may collect information through business interaction with you or your employees. 
</p>
        <p>c. From other sources: We may receive information from other sources, such as public databases; joint marketing partners; social media platforms; or other third parties such as: 
</p>
        <p>I. Updated delivery and address information from our carriers or other third parties, which we use to correct our records and deliver your next purchase or communication more easily. 
</p>
<p>II. Information about your interactions with the products and services offered by our subsidiaries.
</p>
</section>

<section style={styles.section}>
    <h2>3. INFORMATION WE COLLECT</h2>
    <p>a. We collect information primarily to provide better services to all of our customers. 
</p>
    <p>b. We collect the following information from you when you use or sign up on our App: Name, Phone Number, Email ID, Address, Precise Geolocation 
</p>
    <p>c. We do collect the following sensitive information when you use or sign up on our App: Payment Information for the purpose of processing payments while availing services 
</p>
    <p>d. When you visit our App, some information is automatically collected. This may include information such as the type of mobile device, Operating System (OS) running on your device, Internet Protocol (IP) address, unique user ID, access times, device type, and language. We also collect information about how you use Our products or services. 
</p>
    <p>e. We automatically collect purchase or content use history, which we sometimes aggregate with similar information from other customers to create features such as Best Seller, Top Rated, etc... 
</p>
    <p>f. The information about your usage of the App, including crash logs and usage statistics. 
</p>
    <p>g. Information about the location of your device, including geolocation information. 
</p>
    <p>h. We automatically collect information using 'Cookies". Cookies are small data files stored on your device. Among other things, cookies help us to improve our App, our marketing activities, and your experience. We use cookies to see which areas and features are popular and to count visits to our App. 
</p>
    <p>i. By using this App, you are agreeing that We may advertise your feedback on the App and other marketing materials. 
</p>
    <p>j. We will retain your information as long as we require this to provide you with the goods and services and for such period as mandated by the laws concerned. 
</p>
    <p>k. If you opt to receive marketing correspondence from us, subscribe to our mailing list or newsletters, enter into any of our competitions or provide us with your details at networking events, we may use your personal data for our legitimate interests in order to provide you with details about our goods, services, business updates and events. 
</p>
</section>
      
<section style={styles.section}>
    <h2>4. HOW WE USE INFORMATION</h2>
    <p>a. We use the information we collect primarily to provide, maintain, protect, and improve our current products and services. 
</p>
    <p>b. We use the information collected through this App as described in this policy and we may use your information to: 
</p>
    <p>I. Improve our services, App and how we operate our businesses; 
</p>
    <p>II. Understand and enhance your experience using our App, products and services; 
    </p> 
    <p>III. Personalize our products or services and make recommendations; 
    </p>
    <p>IV. Provide and deliver the products and services you request; 
    </p>
    <p>V. Process, manage, complete, and account for transactions; 
    </p>
    <p>VI. Provide customer support and respond to your requests, comments, and inquiries; 
    </p>
    <p>VII. Create and manage the online accounts you manage on our App; 
    </p>
    <p>VIII. Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages; 
    </p>
    <p>IX. Communicate with you about promotions, upcoming events, and news about products and services; 
    </p>
    <p>X. We may process your personal information without your knowledge or consent where required by applicable law or regulation for the purposes of verification of identity or for prevention, detection, or investigation, including of cyber incidents, prosecution, and punishment of offences; 
    </p>
    <p>XI. Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.
    </p>
    </section>

    <section style={styles.section}>
    <h2>5. DATA TRANSFER</h2>
    <p>a. Information about our users is an important part of our business and we take due care. 
    </p>
    <p>b. We share your data with your consent to complete any transaction or provide any product or service you have requested or authorized. We also share data with our affiliates and subsidiaries, with vendors working on our behalf. 
    </p>
    <p>c. We may employ other companies and individuals to perform functions on our behalf. The functions include fulfilling orders for products or services, delivering packages, sending postal mail and e-mail, removing repetitive information from customer lists, providing marketing assistance, providing search results and links (including paid listings and links), processing payments, transmitting content, scoring credit risk, and providing customer service. 
    </p>
    <p>d. These third-party service providers have access to personal information needed to perform their functions but may not use it for other purposes. Further, they must process the personal information in accordance with this Privacy Policy and as permitted by applicable data protection laws. 
    </p>
    <p>e. We release accounts and other personal information when we believe it is appropriate to comply with the law, enforce or apply our conditions of use, and other agreements, and protect the rights, property or safety of Us, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. 
    </p>
    </section>

    <section style={styles.section}>
    <h2>6. DATA SECURITY</h2>
    <p>a. We take due care to protect customer data. Technical measures are in place to prevent unauthorized or unlawful access to data and against accidental loss or destruction of, or damage to the data. The employees who are dealing with the data have been trained to protect the data from any illegal or unauthorized usage. 
    </p>
    <p>b. We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling branded credit cards from the major card schemes. 
    </p>
    <p>c. We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personal customer information. 
    </p>
    <p>d. We take reasonable steps to help protect your personal information in an effort to prevent loss, misuse, unauthorized access, disclosure alteration and destruction. It is your responsibility to protect your user names and passwords to help prevent anyone from accessing or abusing your accounts and services. You should not use or reuse the same passwords you use with other accounts as your password for our services. 
    </p>
    <p>e. It is important for you to protect against unauthorized access to your password, your devices, and applications. Be sure to sign off when you finish using a non-personal device. 
    </p>
    <p>f. The information you provide to us is shared on our secure servers. We have implemented appropriate physical, technical and organizational measures designed to secure your information against accidental loss and unauthorized access, use, alteration, or disclosure. In addition, we limit access to personal data to those employees, agents, contractors, and other third parties that have a legitimate business need for such access. 
    </p>
    <p>g. Information collected from you will be stored for such period as required to complete the transaction entered into with you or such period as mandated under the applicable laws. 
    </p>
    </section>

    <section style={styles.section}>
    <h2>7. LINKS TO THIRD-PARTY SITES/APPS</h2>
    <p>Our App may, from time to time, contain links to and from other Apps/ Websites of third parties. Please note that if you follow a link to any of these Apps/Websites, such Apps/Websites will apply different terms to the collection and privacy of your personal data, and we do not accept any responsibility or liability for these policies. When you leave our Site, we encourage you to read the privacy policy of every App/ Website you visit. 
    </p>
    </section>

    <section style={styles.section}>
    <h2>8. SOCIAL NETWORK PLUGINS</h2>
    <p>This App incorporates plugins and/or buttons for social networks, in order to allow easy sharing on your favourite social networks. These plugins are programmed so as not to set any cookies when assessing the page to safeguard the privacy of users. Cookies may be set, if you make voluntary use of the plugin. The collection and use of information obtained by means of the plugin are governed by the respective privacy policies of the social networks. 
    </p>
    </section>

    <section style={styles.section}>
    <h2>9. SHARING OF PERSONAL INFORMATION</h2>
    <p>a. We do not share your personal data with third parties without your prior consent other than: 
    </p>
    <p>I. With third parties who work on our behalf provided such third parties adhere to the data protection principles set out in the IT Act, 2000 (21 of 2000) and other applicable legislation, or enter into a written agreement with Us requiring that the third party provide at least the same level of privacy protection as is required by such principles; 
    </p>
    <p>II. To comply with laws or to respond to lawful requests and legal process; 
    </p>
    <p>III. To protect the rights and property of Us, our agents, customers, and others including to enforce our agreements, policies and terms of use; 
    </p>
    <p>IV. In an emergency, including to protect the personal safety of any person; and 
    </p>
    <p>V. For the purpose of a business deal (or negotiation of a business deal) involving the sale or transfer of all or a part of our business or assets (business deals may include, for example, any merger, financing, acquisition, divestiture or bankruptcy transaction or proceeding). 
    </p>
    </section>

    <section style={styles.section}>
    <h2>10. CHILDREN</h2>
    <p>If you are under the age of 18 or the age of majority in the jurisdiction in which you reside, you may only use Our App with the consent of your parent or legal guardian. In any case, We will not be liable for any cause of action that arose due to non-compliance with this section. 
    </p>
    </section>

    <section style={styles.section}>
    <h2>11. CHANGES TO THIS POLICY</h2>
    <p>We may change this policy from time to time. If we change this policy, we will update the “Last Updated” date above. You agree that your continued use of our services after such changes have been published to our services will constitute your acceptance of such revised policy. 
    </p>
    </section>

    <section style={styles.section}>
    <h2>12. NEWSLETTER</h2>
    <p>a. Following your subscription to the newsletter, your e-mail address is used for our advertising purposes until you cancel the newsletter again. Cancellation is possible at any time. The following consent has been expressly granted by you separately, or possibly in the course of an ordering process: (I am accepting to receive newsletters from this App), you may revoke your consent at any time with future effect. If you no longer want to receive the newsletter, then unsubscribe by clicking the “Unsubscribe” option given in the email footer.  
    If you have any concerns about privacy or grievances with us, please contact us with a thorough description and we will try to resolve the issue for you. 
</p>
    </section>

    <section id="delete-account" style={styles.section}>
    <h2>13. ACCOUNT DELETION AND DATA REMOVAL</h2>
    <p>Right to Delete Your Data</p>
    <p>You have the right to request the deletion of your account and any personal data associated with it at any time. Upon your request, we will delete your account and all relevant personal information from our systems, unless retention is required for legal or compliance reasons.
    </p>
    <p>How to Delete Your Account</p>
    <p>To delete your account, follow these steps:</p>
    <p>1. Log into your account.</p>
    <p>2. Navigate to the account settings.</p>
    <p>3. Select the option to delete your account.</p>
    <p>Once you submit a deletion request, we will:</p>
    <p>Remove your personal data, including your profile information, usage history, and any associated content from our databases.</p>
    <p>Terminate any active sessions or tokens.</p>
    <p>If applicable, notify third-party services with which we share your data to delete it, in accordance with our agreements with them.
    </p>
    <p>Data Retention Post-Deletion</p>
    <p>After your account is deleted, some information may be retained for a period to:</p>
    <p>Comply with legal obligations (such as tax, fraud prevention, or other regulatory requirements).</p>
    <p>Resolve disputes.</p>
    <p>Enforce our agreements.</p>
    <p>Where applicable, this information will either be securely archived or anonymized.</p>
    <p>Confirmation of Deletion</p>
    <p>Once your request has been processed, you will receive a confirmation of account and data deletion via the email associated with your account.
    </p>
    <p>Please note that data deletion is permanent and cannot be undone. If you wish to use our services again, you will need to create a new account.</p>
    </section>

    <section style={styles.section}>
    <h2>Contact Us</h2>
    <p>If you have questions or comments about Privacy Policy, please contact us at:</p>
    <p>Axzora Private Limited</p>
    <p>4551 Zimmerman Ave, Niagara Falls, ON, L2E 3M5, Canada </p>
    <p>Contact Person: Joy Chakraborty</p>
    <p>Email: joy@axzoragroup.com</p>
    <p>Helpline: +1-6399991188</p>
    </section>

    </div>
    
  );
};

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
  section: {
    marginTop: '1.5rem',
  },
};

export default Privacy;
