import React, { useState } from "react";
import { baseURL } from "../../../utility/axiosInstance";

const ImageUploadButton = ({ stateData, setStateData, refData, view }) => {
  const [localImage, setLocalImage] = useState(null);
  const url = baseURL.replace(/\/api$/, "");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setStateData(file);
    if (file) {
      setLocalImage(URL.createObjectURL(file));
    }
  };

  return (
    <div className="w-[200px] flex justify-center relative bg-gray-200 rounded-3xl">
      <div className={`h-[150px] w-auto relative rounded-3xl`}>
        <img
          src={localImage || `${url}/${stateData}`}
          className={` ${
            stateData || localImage ? "" : "hidden"
          } rounded-3xl h-[150px] w-[250px]`}
          alt=""
        />
        <input
          className="hidden"
          type="file"
          ref={refData}
          onChange={handleFileChange}
        />

        <div
          className={`absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center ${
            stateData || localImage ? "bg-black bg-opacity-50 rounded-3xl" : ""
          }`}
        >
          <span
            className={`text-white ${
              stateData || localImage ? "visible" : "invisible"
            }`}
          >
            {view}
          </span>
        </div>

        <div
          className={`cursor-pointer absolute bottom-1 left-1/2 transform -translate-x-1/2 px-8 bg-opacity-100 py-1 border-gray-600 rounded-xl border-[1px] ${
            stateData ? "text-white bg-red-900" : "text-[#252525]"
          }`}
          onClick={() => {
            refData.current.click();
          }}
        >
          <span className="z-50">{stateData ? "Change" : "Upload"}</span>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadButton;
