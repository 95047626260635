import React, { useEffect, useState } from "react";

import { IoIosCheckmarkCircle, IoIosCloseCircleOutline } from "react-icons/io";
import PrimaryButton from "../../utils/buttons/primaryButton";
import axiosInstance, { notifySuccess } from "../../../utility/axiosInstance";
import { serviceRequests } from "../../../utility/api";

export const ServiceBookingCard = ({
  currentStepInfo,
  onUpdate,
  data,
  setData,
  setServiceStatus,
  setBookModal,
}) => {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);

  const [rescheduleDate, setRescheduleDate] = useState("");
  const [isReschedule, setIsReschedule] = useState(false);
  const [error, setError] = useState(null);
  const [allchecked, setAllChecked] = useState([]);

  const {
    user: { lastName, email, phone, firstName },
    _id,
    serviceRequest,
    car: { brand, model },
    requestedAt: { date, proposedDate },
    status: serviceStatus,
    payment: { paymentStatus,paymentType },
  } = data;

  function handleChange(e) {
    if (e.target.checked) {
      setAllChecked([...allchecked, e.target.value]);
    } else {
      setAllChecked(allchecked.filter((item) => item !== e.target.value));
    }
  }
  const onSRReschedule = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(
        `${serviceRequests.updateServiceRequest}/${_id}`,
        {
          proposedDate: rescheduleDate ?? "",
          rescheduleStatus: "rescheduleProposed",
        }
      );
      console.log("response", response);
      if (response.status === 200) {
        notifySuccess("Service Request has been updated");
        setData((prevData) =>
          prevData.map((item) =>
            _id === item?._id
              ? {
                  ...item,
                  requestedAt: {
                    ...item.requestedAt,
                    proposedDate: rescheduleDate ?? "",
                    status: "rescheduleProposed",
                  },
                }
              : item
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(serviceStatus);
  const onSRStatusChange = async (
    status = "accepted",
    serviceRequestStatus
  ) => {
    if (allchecked?.length === 0 && status == "accepted") {
      setError("Please Select at least one");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.put(
        `${serviceRequests.updateServiceRequest}/${_id}`,
        {
          status: status,
          serviceRequests: allchecked,
          serviceRequestStatus: serviceRequestStatus,
        }
      );
      console.log("response", response);
      if (response.status === 200) {
        notifySuccess("Service Request has been updated");
        if (status == "accepted") {
          setData((prevData) =>
            prevData.map((item) => (_id === item?._id ? response.data : item))
          );
        }
        setBookModal(false);
        if (status == "rejected") {
          setData((prevData) => prevData.filter((item) => _id !== item?._id));
        }
      }
    } catch (error) {
      setError(error.message || "Failed to update reschedule");
    } finally {
      setLoading(false);
      setIsReschedule(!isReschedule);
    }
  };

  return (
    <>
      <div className="rounded-lg p-6 min-w-[350px] mx-auto bg-[#EDEDEDED]">
        <div className="flex gap-6 mb-5">
          <span
            onClick={() => {
              setError(null);
              setCurrent(0);
            }}
            className={` ${
              current === 0 ? "underline" : ""
            } font-semibold text-gray-800 text-sm cursor-pointer`}
          >
            Details
          </span>
          <span
            onClick={() => {
              setError(null);
              setCurrent(1);
            }}
            className={` ${
              current === 1 ? "underline" : ""
            } font-semibold text-gray-800 text-sm  cursor-pointer`}
          >
            Service Requests
          </span>
        </div>
        {current === 0 ? (
          <div className="space-y-4 mb-4 p-3 bg-white rounded-lg shadow-md max-w-full">
            <div className="flex justify-between items-center border-b pb-2">
              <span className="font-semibold text-gray-800 text-sm">Name:</span>
              <span className="text-gray-600 text-sm">
                {firstName + " " + lastName}
              </span>
            </div>

            <div className="flex justify-between items-center border-b pb-3">
              <span className="font-semibold text-gray-800 text-sm">
                Email Id:
              </span>
              <span className="text-gray-600 text-sm">{email}</span>
            </div>

            {phone ? (
              <div className="flex justify-between items-center border-b pb-3">
                <span className="font-semibold text-gray-800 text-sm">
                  Phone Number:
                </span>
                <span className="text-gray-600 text-sm">{phone}</span>
              </div>
            ) : null}

            <div className="flex justify-between items-center border-b pb-3">
              <span className="font-semibold text-gray-800 text-sm">
                Model:
              </span>
              <span className="text-gray-600 text-sm">
                {brand} {model}
              </span>
            </div>

            <div className="flex justify-between items-center border-b pb-3">
              <span className="font-semibold text-gray-800 text-sm">
                Appointment Date:
              </span>
              <span className="text-gray-600 text-sm">
                {new Date(date).toLocaleDateString()}
              </span>
            </div>
            <div className="flex justify-between items-center border-b pb-3">
              <span className="font-semibold text-gray-800 text-sm">
                Status:
              </span>
              <span className="text-gray-600 text-sm">{serviceStatus}</span>
            </div>
            <div className="flex justify-between items-center border-b pb-3">
              <span className="font-semibold text-gray-800 text-sm">
                Payment Type:
              </span>
              <span className="text-gray-600 text-sm">{paymentType}</span>
            </div>
            {proposedDate ? (
              <div className="flex justify-between items-center border-b pb-3">
                <span className="font-semibold text-gray-800 text-sm">
                  Proposed Date:
                </span>
                <span className="text-gray-600 text-sm flex items-center gap-2">
                  {new Date(proposedDate).toLocaleDateString()}
                </span>
              </div>
            ) : null}
            {proposedDate ? (
              <div className="flex justify-between items-center border-b pb-3">
                <span className="font-semibold text-gray-800 text-sm">
                  Proposed Status:
                </span>
                <span className="text-gray-600 text-sm flex items-center gap-2">
                  {data?.requestedAt.status === "rescheduleConfirmed" ? (
                    <span className="text-green-600">Confirmed</span>
                  ) : data?.requestedAt.status === "rescheduleRejected" ? (
                    <span className="text-yellow-600">Rejected</span>
                  ) : (
                    <span className="text-yellow-600">Pending</span>
                  )}
                </span>
              </div>
            ) : null}
            {/* <div className="text-center mt-4">
              <p className="text-gray-700 italic text-sm">{paymentStatus}</p>
            </div> */}
            {paymentStatus === "pending" && serviceStatus !== "rejected" ? (
              <div className="mt-0 p-4">
                <span>Select your Reschedule date</span>
                <div className="flex gap-1 justify-center items-center min-w-full">
                  <input
                    type="datetime-local"
                    value={proposedDate ? proposedDate : rescheduleDate}
                    onChange={(e) => setRescheduleDate(e.target.value)}
                    className="w-full border border-gray-300 rounded mt-2 h-12 mb-1"
                  />

                  <PrimaryButton
                    type="button"
                    onClick={onSRReschedule}
                    disabled={loading}
                    loading={loading}
                    className={`${
                      loading ? "bg-gray-500" : "bg-[#990000]"
                    } hover:bg-[#990000] text-white font-semibold p-0`}
                    title={"Submit"}
                  />
                  <PrimaryButton
                    type="button"
                    onClick={() => onSRStatusChange("rejected", "rejected")}
                    disabled={loading}
                    loading={loading}
                    className={`${
                      loading ? "bg-gray-500" : "bg-[#990000]"
                    } hover:bg-[#990000] text-white font-semibold p-0`}
                    title={"Reject"}
                  />
                </div>
                {error && <p className="text-red-600 mt-2">{error}</p>}
              </div>
            ) : null}
          </div>
        ) : null}
        {current == 1 && (
          <div className="space-y-4 mb-4 p-3 bg-white rounded-lg shadow-md max-w-full ">
            <div className="flex justify-between items-center pb-3">
              <span className="font-semibold text-gray-800 text-sm  w-40">
                Service Name
              </span>
              <span className="font-semibold text-gray-800 text-sm  w-40">
                Status
              </span>
              {/* <span className="font-semibold text-gray-800 text-sm"></span> */}
            </div>
            {serviceRequest.map(({ service: { serviceName }, status, _id }) => {
              return (
                <div
                  className="flex justify-between items-center border-b pb-3"
                  key={_id}
                >
                  <span className="text-gray-600 text-sm w-40">
                    {serviceName}
                  </span>
                  <span className="text-gray-600 text-sm  w-40">{status}</span>
                  {serviceStatus === "accepted" ||
                  data?.requestedAt.status === "rescheduleProposed" ||
                  serviceStatus === "rejected" ? null : (
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      value={_id}
                      // defaultChecked={isAccepted}
                      // checked={isAccepted}
                    />
                  )}
                </div>
              );
            })}
            {serviceStatus === "accepted" ||
            serviceStatus === "rejected" ? null : (
              <div className="flex gap-5 items-center pb-3 mt-auto ">
                <PrimaryButton
                  type="button"
                  onClick={() => onSRStatusChange("accepted", "accepted")}
                  disabled={loading}
                  loading={loading}
                  className={`${
                    loading ? "bg-gray-500" : "bg-[#990000]"
                  } hover:bg-[#990000] text-white font-semibold p-0`}
                  title={"Accept"}
                />
                {/* <PrimaryButton
                  type="button"
                  onClick={handleReschedule}
                  disabled={loading}
                  loading={loading}
                  className={`${
                    loading ? "bg-gray-500" : "bg-[#990000]"
                  } hover:bg-[#990000] text-white font-semibold p-0`}
                  title={"Reject"}
                /> */}
                {error && <p className="text-red-600 mt-2">{error}</p>}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
