import React, { useEffect, useState } from "react";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../../utility/axiosInstance"; // Make sure to import your axios instance
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import AcceptButton from "../staticButtons/acceptButton";
import RejectButton from "../staticButtons/rejectButton";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import {
  leftIcon,
  rightIcon,
  disabledLeftIcon,
  disabledRightIcon,
} from "../../assets";
import {
  orders, // Updated API endpoint
} from "../../utility/api";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import useSocket from "../../hooks/useSocket";

import {
  fetchNotifications,
  clearNotifications,
} from "../../redux/notificationsThunk";

// Define mock data fetching function
const fetchOrders = async () => {
  try {
    const response = await axiosInstance.get(orders.getOrders);
    return response.data;
  } catch (error) {
    console.error("Error fetching data", error);
    return [];
  }
};

export const OrderRequestTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageIndex: 0, // initial page index
    pageSize: 5, // default page size
  });
  const [sorting, setSorting] = useState([]);
  const searchTabs = useSelector((state) => state.search);

  const dispatch = useDispatch();

  const fetchData = async () => {
    setLoading(true);

    const allData = await fetchOrders();

    const searchText = searchTabs.searchText.toLowerCase();

    console.log(searchTabs, "searchText");

    const filteredData = allData.filter((item) => {
      const fullName =
        `${item.user.firstName} ${item.user.lastName}`.toLowerCase();
      return (
        fullName.includes(searchText) ||
        item.user.email.toLowerCase().includes(searchText) ||
        item.item.productName.toLowerCase().includes(searchText)
      );
    });

    const pendingData = allData.filter((item) => item.status === "pending");
    setData(pendingData);
    setLoading(false);
  };

  // useSocket();
  const { list } = useSelector((state) => state.notifications);

  useEffect(() => {
    fetchData();

    dispatch(fetchNotifications());

    // socket.on('newServiceRequest', (notification) => {
    //   dispatch(addNotification(notification.message));
    //   fetchData();
    // });

    // socket.on('newOrderRequest', (notification) => {
    //   dispatch(addNotification(notification.message));
    //   fetchData();
    // });

    // return () => {
    //   socket.off('newServiceRequest');
    //   socket.off('newOrderRequest');
    // };
    console.log(data, "orders");
  }, [dispatch, searchTabs.searchText]);

  const handleSwitchStatus = async (info, status) => {
    console.log(info, "info");
    try {
      const response = await axiosInstance.put(
        `${orders.updateOrders}/${info.row.original._id}`,
        {
          status: status ? "accepted" : "rejected",
        }
      );
      if (response.status === 200) {
        console.log(response.data, "response");
        if (response.data?.status == "accepted") {
          notifySuccess("Order request has been accepted");
        } else {
          toast.warn("Order request has been rejected");
        }
        await fetchData(); // Re-fetch data after update
      } else {
        console.error("Error updating status", response);
      }
    } catch (error) {
      notifyError(`Error updating status ${error}`);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("_id", {
      cell: (info) => info.getValue(),
      header: "ID",
    }),
    columnHelper.accessor(
      (row) => row.user.firstName + " " + row.user.lastName,
      {
        header: () => "Name",
        id: "name",
        cell: (info) => <i>{info.getValue()}</i>,
      }
    ),
    columnHelper.accessor((row) => row.user.email, {
      header: () => "Email",
      id: "email",
      cell: (info) => <i>{info.getValue()}</i>,
    }),
    columnHelper.accessor((row) => row.item.productName, {
      header: () => "Product",
      id: "productName",
      cell: (info) => <i>{info.getValue()}</i>,
    }),
    columnHelper.accessor((row) => row.item.price, {
      header: () => "Price",
      id: "price",
      cell: (info) => <i>{info.getValue()}</i>,
    }),
    columnHelper.accessor((row) => row.quantity, {
      header: () => "Quantity",
      id: "quantity",
      cell: (info) => <i>{info.getValue()}</i>,
    }),
    columnHelper.accessor((row) => row.offerApplied?.discount, {
      header: () => "Discount",
      id: "offerApplied",
      cell: (info) => <i>{info.getValue() || 0}</i>,
    }),
    columnHelper.accessor((row) => row.totalPrice, {
      header: () => "Total Price",
      id: "totalPrice",
      cell: (info) => <i>{info.getValue()}</i>,
    }),

    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => (
        <div className="flex gap-3 justify-center">
          <AcceptButton accept={() => handleSwitchStatus(info, true)} />
          <RejectButton reject={() => handleSwitchStatus(info, false)} />
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="">
      {loading ? (
        <div>Loading...</div>
      ) : data?.length == 0 ? (
        <div className="text-center p-8">No orders request yet</div>
      ) : (
        <table className="w-full border-borderColor border-y-[1px]">
          <thead className="w-full">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className="border-borderColor font-medium text-sm border-x-[1px] py-2 px-1"
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border-borderColor text-base font-medium border-[1px] px-1 py-2 m-auto"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div
        className={`flex justify-center mt-5 gap-10 ${
          data?.length == 0 && "hidden"
        }`}
      >
        <button
          className="px-4 py-2 border-gray-300 rounded-sm flex items-center border-[1px]"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {!table.getCanPreviousPage() ? (
            <img src={disabledLeftIcon} alt="" />
          ) : (
            <img src={leftIcon} alt="" />
          )}
        </button>
        <div className="flex gap-5">
          {Array.from({ length: table.getPageCount() }, (_, index) => (
            <button
              key={index}
              className={`px-4 py-2 border rounded-sm ${
                index === pagination?.pageIndex
                  ? "border-redBackground text-redBackground"
                  : "border-gray-200"
              }`}
              onClick={() => setPagination({ ...pagination, pageIndex: index })}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          className={`px-4 py-2 border-gray-300 rounded-sm flex items-center border-[1px]`}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {!table.getCanNextPage() ? (
            <img src={disabledRightIcon} alt="" />
          ) : (
            <img src={rightIcon} alt="" />
          )}
        </button>
      </div>
    </div>
  );
};
