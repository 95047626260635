import { RiEditBoxFill } from "react-icons/ri";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdLogout } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";

export const icons = {
  editIcon: <RiEditBoxFill size={15} />,
  deleteIcon: <RiDeleteBin5Fill size={15} />,
  logOut: <MdLogout />,
  settings: <IoSettingsOutline />,
};
