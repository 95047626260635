import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { CustomerTable } from "./CustomerTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../redux/customersThunk";
import { fetchServices } from "../../redux/serviceThunk";
import { setCustomersOptionSelected } from "../../redux/searchSlice";
import NoDataFound from "../NoDataFound/NoDataFound";

export const Customers = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { list: customers, loading } = useSelector((state) => state.customers);

  const { list: services } = useSelector((state) => state?.services);

  const searchTabs = useSelector((state) => state.search);

  useEffect(() => {
    dispatch(fetchCustomers());
    dispatch(fetchServices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCustomersOptionSelected({ isActive: true, searchText: "" }));
    return () => {
      dispatch(setCustomersOptionSelected({ isActive: false, searchText: "" }));
    };
  }, []);

  const filteredCustomers = customers.filter((item) => {
    const fullName = `${item?.firstName} ${item?.lastName}`.toLowerCase();

    return (
      fullName.includes(searchTabs.searchText) ||
      item?.email.toLowerCase().includes(searchTabs.searchText) ||
      item?.phone?.includes(searchTabs.searchText)
    );
  });

  return (
    <div className="h-[calc(100vh-120px)] overflow-y-auto">
      {filteredCustomers?.length == 0 && (
        <div className="h-[100%]">
          {<NoDataFound message={"No Customers found"} />}
        </div>
      )}

      <div
        className={`flex px-5 ${filteredCustomers?.length == 0 && "hidden"}`}
      >
        <div className="w-full my-2 overflow-y-auto">
          <CustomerTable data={filteredCustomers} services={services} />
        </div>
      </div>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="relative z-50"
      >
        <div className=" fixed inset-0 bg-black/30 flex w-screen items-center justify-center p-4">
          <div className="h-[700px] w-[700px] relative bg-white">
            <div className="border-borderColor border-b-[1px] pb-5">
              <div
                onClick={() => {
                  setOpenModal(false);
                }}
                className="absolute h-[20px] w-[20px] bg-redBackground text-white flex justify-center items-center right-1 top-1 rounded-full cursor-pointer"
              >
                x
              </div>
              <div className="text-lg pt-2 px-3">Add Car</div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
