import React, { useEffect, useState } from "react";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../../redux/serviceThunk";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../../../utility/axiosInstance";

function PaymentCard({ data, setData }) {
  const dispatch = useDispatch();
  const [allchecked, setAllChecked] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [serviceCharges, setServiceCharges] = useState({
    additionalCharges: 0,
    taxesFee: 0,
  });

  function handleChange(e) {
    if (e.target.checked) {
      setAllChecked([...allchecked, e.target.value]);
    } else {
      setAllChecked(allchecked.filter((item) => item !== e.target.value));
    }
  }
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get(
          "serviceRequest/getAdditionServiceInfo"
        );

        if (response?.status === 200) {
          setServiceCharges(response.data.allTaxFee);
        }
      } catch (error) {
        notifyError(`Failed to fetch notifications:, ${error}`);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const { _id } = data;
    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get(
          "serviceRequest/invoice/" + _id
        );

        if (response?.status === 200) {
          console.log(response.data);
          if (response.data.invoice) {
            setInvoice(response.data.invoice);
            const { totalAmount, discount, additionalCharges } =
              response.data.invoice;

            // setAmount((state) => ({
            //   ...state,
            //   additionalCharges: additionalCharges,
            //   discount: discount,
            //   totalAmount:totalAmount,
            // }));
          }
          // setServiceCharges(response.data.serviceCharges);
        }
      } catch (error) {
        notifyError(`Failed to fetch notifications:, ${error}`);
      }
    };
    fetchCurrentUser();
  }, [data]);

  const { list } = useSelector((state) => state?.services);
  const [amount, setAmount] = useState({
    actualAmount: 0,
    serviceTip: 0,
    taxFee: 0,
    discount: 0,
    totalAmount: 0,
    additionalServiceCharges: 0,
    additionalCharges: 0,
  });

  const [loading, setLoading] = useState(false);

  const [serviceList, setServiceList] = useState({
    isActive: false,
    serviceRequestIds: [],
    remainingServices: [],
  });

  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  useEffect(() => {
    let additionalCharges = 0;
    if (allchecked.length !== 0) {
      serviceList.remainingServices.forEach(({ _id, price }) => {
        if (allchecked.includes(_id)) {
          additionalCharges += price;
        }
      });
      // console.log("additionalCharges", additionalCharges);
    }
    setAmount((state) => ({
      ...state,
      additionalServiceCharges: additionalCharges,
    }));
  }, [allchecked]);

  useEffect(() => {
    setAmount((state) => ({
      ...state,
      totalAmount:
        state.additionalCharges +
        state.actualAmount -
        state.discount +
        state.taxFee,
    }));
  }, [amount.additionalCharges, amount.discount]);

  useEffect(() => {
    try {
      if (list?.length !== 0) {
        const remainingServices = list.filter(
          ({ _id }) => !serviceList?.serviceRequestIds.includes(_id)
        );
        setServiceList((state) => ({
          ...state,
          remainingServices: remainingServices,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }, [list, serviceList?.serviceRequestIds]);

  const {
    serviceRequest,
    _id,
    payment: { paymentStatus, paymentType },
    requestedAt: { status: reqStatus },
    status,
  } = data;

  useEffect(() => {
    let actualAmount = 0;
    const serviceRequestIds = [];

    if (status === "accepted" || status === "completed") {
      serviceRequest.forEach(({ service: { price, _id }, status }) => {
        if (status === "accepted") {
          serviceRequestIds.push(_id);
          actualAmount += Number(price);
        }
      });
    } else {
      serviceRequest.forEach(({ service: { price, _id }, status }) => {
        serviceRequestIds.push(_id);
        actualAmount += Number(price);
      });
    }
    // console.log("serviceRequestIds", serviceRequestIds);
    setServiceList((state) => ({
      ...state,
      serviceRequestIds: serviceRequestIds,
    }));

    const { rate } = serviceCharges;
    const tax = rate ? rate : 0;
    console.log("serviceCharges", serviceCharges);
    let ac =0
    let dis = 0;
    if (invoice) {
      let { additionalCharges, discount } = invoice;

      ac = additionalCharges ? additionalCharges : 0;
      dis = discount ? discount : 0;
    }

    setAmount((state) => ({
      ...state,
      actualAmount: actualAmount,
      taxFee: tax,
      // additionalCharges: additionalCharges,
      totalAmount: ac + actualAmount - dis + tax,
    }));
  }, [data, serviceCharges, invoice]);

  console.log(amount);
  const onPaymentRequest = async (paymentReq) => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(
        `serviceRequest/payment-request/${_id}`,
        {
          additionalServiceRequests: allchecked,
          actualAmount: amount.actualAmount + amount.additionalServiceCharges,
          totalAmount: amount.totalAmount + amount.additionalServiceCharges,
          taxFee: amount.taxFee,
          additionalCharges: amount.additionalCharges,
          paymentReq: paymentReq,
          discount: amount.discount,
        }
      );
      console.log("response", response);
      if (response.status === 200) {
        notifySuccess("Payment request has sent");
        setAllChecked([]);
        setData((prevData) =>
          prevData.map((item) =>
            _id === item?._id ? response.data.service : item
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const onConfirmPayment = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(
        `serviceRequest/payment-confirm-admin/${_id}`
      );
      if (response.status === 200) {
        notifySuccess("Payment request has sent");
        setData((prevData) =>
          prevData.map((item) =>
            _id === item?._id ? response.data.service : item
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  //   console.log(amount);
  return (
    <div className="rounded-lg p-6 min-w-[350px] mx-auto bg-[#EDEDEDED]">
      {/* <div className="flex gap-6 mb-5">
          <span

            className={` 
            } font-semibold text-gray-800 text-sm `}
          >
           
          </span>
      
        </div> */}

      <div className="space-y-4 mb-4 p-3 bg-white rounded-lg shadow-md max-w-full">
        <CommonText header={"Actual Amount"} text={amount.actualAmount} />
        {status === "accepted" ? (
          <div className="flex justify-between items-center pb-2">
            <span className="font-semibold text-[#990000] text-sm">
              Add Service Additional Charges
            </span>
            <span
              onClick={() =>
                setServiceList((state) => ({
                  ...state,
                  isActive: !state.isActive,
                }))
              }
              className="font-semibold text-[#990000] text-sm cursor-pointer"
            >
              Add
            </span>
          </div>
        ) : null}
        {serviceList.isActive ? (
          <div className="flex flex-col justify-between items-center gap-2  border p-2">
            {serviceList.remainingServices.length === 0 ? (
              <span className=" text-black text-sm w-20">No service</span>
            ) : (
              serviceList.remainingServices.map(
                ({ _id, price, serviceName }) => (
                  <div className="flex justify-between w-full">
                    <span className=" text-black text-sm w-20">
                      {serviceName}
                    </span>
                    <span className=" text-gray-600 text-sm w-20">{price}</span>
                    <input
                      onChange={handleChange}
                      type="checkbox"
                      value={_id}
                      checked={allchecked.includes(_id)}
                    />
                  </div>
                )
              )
            )}
          </div>
        ) : null}
        {/* <CommonText header={"Service Tip (default)"} text={amount.serviceTip} /> */}
        <CommonText
          header={"Additional Service Charges"}
          text={amount.additionalServiceCharges}
        />

        {/* <CommonText header={"Discount"} text={amount.discount} /> */}
        {status == "accepted" ? (
          <>
            <div className="flex justify-between items-center pb-3">
              <span className="font-semibold text-[#757575] text-sm">
                Discount
              </span>
              <input
                type="number"
                className="w-20"
                value={amount.discount}
                onChange={(e) => {
                  console.log(e.target.value);
                  setAmount((state) => ({
                    ...state,
                    discount: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex justify-between items-center pb-3">
              <span className="font-semibold text-[#757575] text-sm">
                Additional Charges
              </span>
              <input
                type="number"
                className="w-20"
                value={amount.additionalCharges}
                onChange={(e) => {
                  setAmount((state) => ({
                    ...state,
                    additionalCharges: e.target.value,
                  }));
                }}
              />
            </div>
          </>
        ) : (
          <>
            <CommonText header={"Discount"} text={amount.discount} />
            <CommonText
              header={" Additional Charges"}
              text={amount.additionalCharges}
            />
          </>
        )}
        <CommonText header={"Taxes & Fee"} text={amount.taxFee} />
        {/* <CommonText header={"Payment Type"} text={paymentType} /> */}
        <div className="flex justify-between items-center py-2 border-t ">
          <span className="font-semibold text-black text-lg ">
            Total Amount
          </span>
          <span className="font-semibold text-gray-600 text-sm text-lg">
            {amount.totalAmount + amount.additionalServiceCharges}
          </span>
        </div>

        <div className="flex  gap-5 items-center">
          {(reqStatus === "scheduled" || reqStatus === "rescheduleConfirmed") &&
          status === "accepted" &&
          paymentStatus !== "completed" &&
          !invoice?.paymentType ? (
            <PrimaryButton
              type="button"
              onClick={() => onPaymentRequest(true)}
              disabled={loading}
              loading={loading}
              className={`${
                loading ? "bg-gray-500" : "bg-[#990000]"
              } hover:bg-[#990000] text-white font-semibold p-0 `}
              title={"Payment Request"}
            />
          ) : null}
          {invoice?.paymentType === "cash" && status !== "completed" ? (
            <PrimaryButton
              type="button"
              onClick={onConfirmPayment}
              disabled={loading}
              loading={loading}
              className={`${
                loading ? "bg-gray-500" : "bg-[#990000]"
              } hover:bg-[#990000] text-white font-semibold p-0 `}
              title={"Confirm Payment"}
            />
          ) : null}

          {(reqStatus === "scheduled" || reqStatus === "rescheduleConfirmed") &&
          status === "accepted" &&
          paymentStatus !== "completed" ? (
            <PrimaryButton
              type="button"
              onClick={() => onPaymentRequest(false)}
              disabled={loading}
              loading={loading}
              className={`${
                loading ? "bg-gray-500" : "bg-[#990000]"
              } hover:bg-[#990000] text-white font-semibold p-0 `}
              title={"Send Invoice"}
            />
          ) : null}
        </div>
        {paymentStatus === "requested" ? (
          <span className=" text-black text-sm text-center">
            Payment Request is already send
          </span>
        ) : null}
      </div>
    </div>
  );
}
const CommonText = ({ header, text }) => (
  <div className="flex justify-between items-center pb-2">
    <span className="font-semibold text-[#757575] text-sm">{header}</span>
    <span className="font-semibold text-[#757575] text-sm">{text}</span>
  </div>
);
export default PaymentCard;
