import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import notificationReducer from "./notificationSlice";
import serviceReducer from "./serviceSlice";
import sparePartsReducer from "./sparePartsSlice";
import usedCarsReducer from "./usedCarsSlice";
import customerReducer from "./customersSlice";
import userReducer from "./userSlice";
import offerReducer from "./offerSlice";
import ordersReducer from "./ordersSlice";
import reminderReducer from "./reminderSlice";
import searchReducer from "./searchSlice";
import usedCarsEnquiriesReducer from "./usedCarsEnquiries/usedCarsEnquiriesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notifications: notificationReducer,
    services: serviceReducer,
    spareParts: sparePartsReducer,
    usedCars: usedCarsReducer,
    customers: customerReducer,
    user: userReducer,
    offers: offerReducer,
    orders: ordersReducer,
    reminders: reminderReducer,
    search: searchReducer,
    usedCarsEnquiries: usedCarsEnquiriesReducer,
  },
});
