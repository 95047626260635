import React, { useContext, useEffect, useState } from "react";
import { ServiceSettingContext } from "../ServiceSettingIndex";
import PrimaryButton from "../../../utils/buttons/primaryButton";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../utility/axiosInstance";
import { toast } from "react-toastify";

const WeekSettingComponent = () => {
  const {
    weekdays,
    weeklyCheckbox,
    setWorkingTime,
    calenderView,
    workingTime,
  } = useContext(ServiceSettingContext);
 

  const handleStartChange = (event, type) => {
    if (type == "h") {
      const hr = event.target.value;
      console.log(hr);
      setWorkingTime((state) => ({
        ...state,
        start: `${hr}:${state.start.split(":")[1]}`,
      }));
    } else {
      const min = event.target.value;
      setWorkingTime((state) => ({
        ...state,
        start: `${state.start.split(":")[0]}:${min}`,
      }));
    }
  };

  const handleEndChange = (event, type) => {
    if (type == "h") {
      const hr = event.target.value;
      setWorkingTime((state) => ({
        ...state,
        end: `${hr}:${state.end.split(":")[1]}`,
      }));
    } else {
      const min = event.target.value;
      setWorkingTime((state) => ({
        ...state,
        end: `${state.end.split(":")[0]}:${min}`,
      }));
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleEDAToggle = async () => {
    try {
      console.log(weeklyCheckbox);
      const response = await axiosInstance.put(
        "servicesSetting/updateServiceSettingInfo",
        {
          weeklySetting: weeklyCheckbox,
          workingTime,
          calenderView,
        }
      );

      if (response?.status === 200) {
        toast.success("Changed the Status");
        // setInfo(response.data.parkingLot);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="flex flex-col gap-4 w-56  ">
      <h2 className="mb-1">Select Time</h2>
      <div className="mb-10 flex flex-col gap-12">
        <TimeSelect
          handleHourChange={(e) => handleStartChange(e, "h")}
          handleMinuteChange={(e) => handleStartChange(e, "min")}
          title={"Start Time"}
          selectedMinute={workingTime?.start.split(":")[1]}
          selectedHour={workingTime?.start.split(":")[0]}
        />
        <TimeSelect
          title={"End Time"}
          handleHourChange={(e) => handleEndChange(e, "h")}
          handleMinuteChange={(e) => handleEndChange(e, "min")}
          selectedMinute={workingTime?.end.split(":")[1]}
          selectedHour={workingTime?.end.split(":")[0]}
        />
      </div>

      <span className="text-lg">Weekly Available Setting</span>
      <div className="flex gap-6">
        <div className="flex flex-col gap-2">
          <span className="text-lg text-center">Day</span>

          {weekdays.map((weekday, index) => (
            <CheckBoxComponent key={index} weekday={weekday} />
          ))}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-lg text-center">All</span>

          {weekdays.map((weekday, index) => (
            <CheckAllBoxComponent key={index} weekday={weekday} />
          ))}
        </div>
      </div>
      <CheckBoxSetCalenderComponent />
      <div className="flex justify-start py-2">
        <PrimaryButton
          width="120px"
          title={"Update"}
          onClick={handleEDAToggle}
          loading={isLoading}
        />
      </div>
    </div>
  );
};
const CheckBoxComponent = ({ weekday }) => {
  const { weeklyCheckbox, setWeeklyCheckbox } = useContext(
    ServiceSettingContext
  );

  return (
    <div className="relative w-40">
      <div className="flex  flex-row justify-between ">
        <label className="block text-base font-medium text-gray-700">
          {weekday}
        </label>
        <input
          type="checkbox"
          checked={weeklyCheckbox?.length != 0 ?weeklyCheckbox[weekday].day : false}
          onChange={(e) => {
            setWeeklyCheckbox((state) => ({
              ...state,
              [weekday]: {
                day: !state[weekday].day,
                all: state[weekday].day ? state[weekday].all : false,
              },
            }));
          }}
          className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
    </div>
  );
};
const CheckAllBoxComponent = ({ weekday }) => {
  const { weeklyCheckbox, setWeeklyCheckbox } = useContext(
    ServiceSettingContext
  );

  return (
    <div className="relative w-40">
      <div className="flex  flex-row justify-between ">
        <label className="block text-base font-medium text-gray-700">
          {weekday}
        </label>
        <input
          type="checkbox"
          checked={weeklyCheckbox?.length != 0 ?weeklyCheckbox[weekday].all :false}
          onChange={(e) => {
            setWeeklyCheckbox((state) => ({
              ...state,
              [weekday]: {
                ...state[weekday],
                all: state[weekday].day
                  ? state[weekday].all
                  : !state[weekday].all,
              },
            }));
          }}
          className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
    </div>
  );
};
const CheckBoxSetCalenderComponent = ({ weekday }) => {
  const { calenderView, setCalenderView } = useContext(ServiceSettingContext);

  return (
    <div className="relative w-40">
      <label className="block text-base font-medium text-gray-700">
        Calender Setting
      </label>
      <div className="flex  flex-row justify-between gap-3 items-center ">
        <label className="block text-base font-medium text-gray-700">
          Week
        </label>
        <input
          type="radio"
          name="week"
          checked={calenderView === "week"}
          onChange={(e) => {
            setCalenderView("week");
          }}
          className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
        />
        <label className="block text-base font-medium text-gray-700">
          Fortnight
        </label>
        <input
          type="radio"
          name="fortnight"
          checked={calenderView === "fortnight"}
          onChange={(e) => {
            setCalenderView("fortnight");
          }}
          className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
        />
        <label className="block text-base font-medium text-gray-700">
          Month
        </label>
        <input
          type="radio"
          name="month"
          checked={calenderView === "month"}
          onChange={(e) => {
            setCalenderView("month");
          }}
          className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
        />
        {/* <label className="block text-base font-medium text-gray-700">All</label>
        <input
          type="radio"
          name="all"
          checked={calenderView === "all"}
          onChange={(e) => {
            setCalenderView("all");
          }}
          className="cursor-pointer mt-1 block w-6 h-6 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
        /> */}
      </div>
    </div>
  );
};

const TimeSelect = ({
  selectedHour,
  selectedMinute,
  handleMinuteChange,
  handleHourChange,
  title,
}) => {
  const hours = Array.from({ length: 24 }, (_, index) => index);
  const minutes = [0, 30];

  return (
    <div className="form-group d-flex flex-col align-items-center gap-2">
      <label className="text-lg">{title}</label>
      <div className="form-group d-flex align-items-center">
        <label className="mr-2">Hour:</label>
        <select
          value={selectedHour}
          onChange={handleHourChange}
          className="form-control w-auto h-10"
        >
          {hours.map((hour) => (
            <option key={hour} value={hour}>
              {hour.toString().padStart(2, "0")}
            </option>
          ))}
        </select>

        <label className="mx-2">Minute:</label>
        <select
          value={selectedMinute}
          onChange={handleMinuteChange}
          className="form-control w-auto h-10"
        >
          {minutes.map((minute) => (
            <option key={minute} value={minute}>
              {minute.toString().padStart(2, "0")}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export default WeekSettingComponent;
