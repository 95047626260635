import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setOffer } from "./offerSlice";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../utility/axiosInstance";
import { offer } from "../utility/api";

export const fetchOffers = createAsyncThunk(
  "offers/fetchOffers",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      const response = await axiosInstance.get(offer.getOffers);

      dispatch(setOffer(response.data));
      dispatch(setLoading(false));
    } catch (error) {
      notifyError(`Error fetching offers: ${error}`);
      dispatch(setLoading(false));
    }
  }
);

export const createOffer = createAsyncThunk(
  "offers/createOffer",
  async (newOffer, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      await axiosInstance.post(offer.createOffer, newOffer);
      dispatch(fetchOffers());
      dispatch(setLoading(false));
    } catch (error) {
      notifyError(`Error creating offers: ${error}`);

      dispatch(setLoading(false));
    }
  }
);

export const deleteOffer = createAsyncThunk(
  "offers/deleteOffer",
  async (id, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      await axiosInstance.delete(`${offer.deleteOffers}/${id}`);
      dispatch(fetchOffers());
      dispatch(setLoading(false));
      notifySuccess("Offer deleted successfully");
    } catch (error) {
      notifyError(`Error deleting offer: ${error}`);
      dispatch(setLoading(false));
    }
  }
);

export const updateOffer = createAsyncThunk(
  "offers/updateOffer",
  async ({ id, updatedOffer }, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      await axiosInstance.put(`${offer.updateOffers}/${id}`, updatedOffer);
      dispatch(fetchOffers());
      dispatch(setLoading(false));
      notifySuccess("Offer updated successfully");
    } catch (error) {
      notifyError(`Error updating offer: ${error}`);
      dispatch(setLoading(false));
    }
  }
);
