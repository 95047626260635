import React from "react";
import { AiOutlineLoading } from "react-icons/ai";

interface PrimaryButtonProps {
  title?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  loading?: boolean;
  className?: string; // Allow className to be passed from parent
  style?: React.CSSProperties; // Allow additional styles to be passed from parent
  icon?: React.ReactNode; // Add icon prop
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  title,
  onClick,
  width,
  height,
  loading = false,
  className = "",
  style = {},
  icon, // Destructure icon prop
}) => {
  return (
    <button
      style={{
        width: width ? width : "",
        height: height ? height : "",
        ...style,
      }}
      className={`bg-redBackground text-white py-3 px-5 rounded-md ${className}`}
      onClick={onClick}
    >
      {loading ? (
        <AiOutlineLoading className="h-6 w-6 animate-spin m-auto" />
      ) : (
        // <div className="loader m-auto h-full"></div>
        <div className="flex items-center justify-center">
          {icon && <span className="mr-2">{icon}</span>}{" "}
          {/* Render icon if provided */}
          <p className={className}>{title}</p>
        </div>
      )}
    </button>
  );
};

export default PrimaryButton;
