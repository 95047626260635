import { createAsyncThunk } from "@reduxjs/toolkit";
import { customers } from "../utility/api";
import { setCustomers, setLoading } from "./customersSlice";
import axiosInstance, { notifyError } from "../utility/axiosInstance";

export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(customers.getAllCustomers);
      dispatch(setCustomers(response.data));
    } catch (error) {
      notifyError(`Failed to fetch notifications:, ${error}`);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
