import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isTokenExpired } from "./isTokenExpired";

export const baseURL = process.env.REACT_APP_API_BASE_URL;

export const notifySuccess = (message) => {
  toast.success(message, {
    theme: "colored",
    style: {
      backgroundColor: "#990000",
    },
  });
};

export const notifyError = (message) => {
  toast.error(message, {
    theme: "colored",
    style: {
      backgroundColor: "#990000",
    },
  });
};

export const notifyWarning = (message) => {
  toast.warning(message, {
    theme: "colored",
    style: {
      backgroundColor: "#990000",
    },
  });
};

const axiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (isTokenExpired(token) && token) {
      localStorage.clear("token")
      window.location.href = "/";
      // return Promise.reject(new Error("Token expired"));
    }

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = error.response?.data?.message || "An error occurred";
    console.log(message)
    if(message == "Authorization denied"){
          return
    }
    notifyError(message);
    return Promise.reject(message);
    // return Promise.reject(error);
  }
);

export default axiosInstance;
