import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  setNotifications,
  setLoading,
  setNotificationCount,
} from "./notificationSlice";
import axiosInstance from "../utility/axiosInstance";
import { notification } from "../utility/api";

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(
        notification.getAllNotifications
      );
      dispatch(setNotifications(response.data.data));
      const unreadCount = response?.data?.data?.filter((n) => !n.isRead).length;
      dispatch(setNotificationCount(unreadCount));
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const clearNotifications = createAsyncThunk(
  "notifications/clearNotifications",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance.delete(notification.deleteAllNotifications);
      dispatch(setNotifications([]));
    } catch (error) {
      console.error("Failed to delete notifications:", error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const markAsAllRead = createAsyncThunk(
  "notifications/markAsAllRead",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance.put(notification.markAsAllRead);
      dispatch(fetchNotifications());
    } catch (error) {
      console.error("Failed to update notifications:", error);
    } finally {
      dispatch(setLoading(false));
    }
  }
);
