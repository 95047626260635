import { notifyWarning } from "./axiosInstance";

const MAX_FILE_SIZE = 1 * 1024 * 1024;

export const validateFileSize = (file, fileName) => {
    if (file && file.size > MAX_FILE_SIZE) {
      notifyWarning(`The file ${fileName} exceeds the maximum size of 1MB.`);
      return false;
    }
    return true;
  };