import React, { useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Dialog } from "@headlessui/react";
import { icons8crossFilled } from "../../assets";
import { getServiceName } from "../../utility/api";

export const CustomerTable = ({ data, services }) => {
  const [viewImage, setViewImage] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const columnHelper = createColumnHelper();


  const columns = [
    columnHelper.accessor("firstName", {
      id: "name",
      cell: (info) => <i className="flex py-2 pl-2">{info.getValue()}</i>,
      header: () => <span className="flex py-2 pl-2">Name</span>,
    }),
    columnHelper.accessor("email", {
      id: "email",
      cell: (info) => <i className="flex py-2 pl-2">{info.getValue()}</i>,
      header: () => <span className="flex py-2 pl-2">Email</span>,
    }),
    columnHelper.accessor("phone", {
      id: "number",
      cell: (info) => <i className="flex py-2 pl-2">{info.getValue()}</i>,
      header: () => <span className="flex py-2 pl-2">Number</span>,
    }),
    columnHelper.accessor("history", {
      header: () => <span className="flex py-2 pl-2">History</span>,
      cell: (info) => (
        <div>
          <p
            onClick={() => {
              setViewHistory(true);
              setSelectedId(info.row.original._id);
            }}
            className="flex cursor-pointer"
          >
            View History
          </p>
          <Dialog
            open={viewHistory && selectedId === info.row.original._id}
            onClose={() => setViewHistory(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30 flex w-screen items-center justify-center">
              <Dialog.Panel className="w-full max-w-3xl mx-4 bg-white rounded max-h-[600px] overflow-auto no-scroolbar">
                <div className="relative p-4">
                  <img
                    src={icons8crossFilled}
                    onClick={() => setViewHistory(false)}
                    className="w-8 h-8 absolute top-2 right-2 cursor-pointer"
                    alt="Close"
                  />
                  <div className="text-lg">
                    <p className="py-4 text-xl font-bold border-borderColor border-b-[1px]">
                      Service Booking History
                    </p>
                    {info.row.original.history.serviceBookingHistory
                      .slice(-3) // Adjust this number to show more or fewer entries
                      .map((item) => (
                        <div key={item._id} className="px-2 py-5">
                          <p className="font-semibold">
                            Service:{" "}
                            <span className="font-medium pl-1">
                            {getServiceName(item?.service,services)}
                            </span>
                          </p>
                          <p className="font-semibold">
                            Status:{" "}
                            <span className="font-medium pl-1">
                              {item.status}
                            </span>
                          </p>
                          <p className="font-semibold">
                            Requested At:{" "}
                            <span className="font-medium pl-1">
                              {new Date(item.requestedAt).toLocaleString()}
                            </span>
                          </p>
                        </div>
                      ))}
                    <p className="py-4 text-xl font-bold border-borderColor border-b-[1px]">
                      Order History
                    </p>
                    {info.row.original.history.orderHistory
                      .slice(-3) // Adjust this number to show more or fewer entries
                      .map((item) => (
                        <div key={item._id} className="px-2 py-5">
                          <p className="font-semibold">
                            Item:{" "}
                            <span className="font-medium pl-1">
                              {item.item}{" "}
                              {/* Replace with actual item name if available */}
                            </span>
                          </p>
                          <p className="font-semibold">
                            Status:{" "}
                            <span className="font-medium pl-1">
                              {item.status}
                            </span>
                          </p>
                          <p className="font-semibold">
                            Ordered At:{" "}
                            <span className="font-medium pl-1">
                              {new Date(item.orderedAt).toLocaleString()}
                            </span>
                          </p>
                          <p className="font-semibold">
                            Delivery Status:{" "}
                            <span className="font-medium pl-1">
                              {item.deliveryStatus}
                            </span>
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </div>
      ),
    }),
    columnHelper.accessor("picture", {
      header: () => <p className="text-start px-2">Image</p>,
      cell: (info) => (
        <div>
          <p
            onClick={() => {
              setViewImage(true);
              setSelectedId(info.row.original._id);
            }}
            className="flex cursor-pointer"
          >
            View Image
          </p>

          <Dialog
            open={viewImage && selectedId === info.row.original._id}
            onClose={() => setViewImage(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30 flex w-screen items-center justify-center">
              <Dialog.Panel className="w-full max-w-xs mx-4 bg-white rounded">
                <div className="relative p-2">
                  <img
                    src={icons8crossFilled}
                    onClick={() => setViewImage(false)}
                    className="w-8 h-8 absolute top-2 right-2 cursor-pointer"
                    alt="Close"
                  />
                  <div className="text-lg">
                    <img
                      className="h-[300px] w-[300px] rounded-full bg-cover"
                      src={info.row.original.picture}
                      alt="User"
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <div className="w-full">
        <table className="w-full border-borderColor border-y-[1px]">
          <thead className="w-full">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className="border-borderColor border-x-[1px]"
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border-borderColor border-[1px] pl-2 py-2"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="h-4" />
      </div>
    </div>
  );
};
