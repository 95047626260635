import { createSlice } from "@reduxjs/toolkit";
import { fetchOffers } from "./offerThunk";

const OfferSlice = createSlice({
  name: "offers",
  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    setOffer: (state, action) => {
      state.list = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.fulfilled, (state, action) => {
        state.list = action.payload;
      });
  },
});

export const { setOffer, setLoading } = OfferSlice.actions;

export default OfferSlice.reducer;
