import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../utility/axiosInstance";
import { notifySuccess, notifyError } from "../utility/axiosInstance";
import { setReminder, setLoading } from "./reminderSlice";
import { reminders } from "../utility/api";

export const fetchReminders = createAsyncThunk(
  "reminders/fetchReminders",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(reminders.getReminders);
      dispatch(setReminder(response.data));
      dispatch(setLoading(false));
    } catch (error) {
      notifyError(`Error fetching orders: ${error}`);
      dispatch(setLoading(false));
    }
  }
);

export const addReminder = createAsyncThunk(
  "reminders/addReminder",
  async (reminderData, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      await axiosInstance.post(`${reminders.getReminders}`, reminderData);
      dispatch(fetchReminders());
    } catch (error) {
      notifyError(`Error adding reminders: ${error}`);
      dispatch(setLoading(false));
    }
  }
);

export const updateReminders = createAsyncThunk(
  "reminders/updateReminder",
  async ({ id, updatedReminder }, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      await axiosInstance.put(
        `${reminders.updateReminders}/${id}`,
        updatedReminder
      );

      dispatch(fetchReminders());
    } catch (error) {
      notifyError(`Error updating reminders: ${error}`);
      dispatch(setLoading(false));
    }
  }
);

export const toggleReminder = createAsyncThunk(
  "reminders/deleteReminder",
  async ({ status, id }, { dispatch }) => {
    setLoading(true);
    try {
      await axiosInstance.put(`${reminders.toggle}/${id}`, status);
      dispatch(fetchReminders());
    } catch (error) {
      notifyError(`Error deleting reminders: ${error}`);
      dispatch(setLoading(false));
    }
  }
);
