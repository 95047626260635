import { createAsyncThunk } from "@reduxjs/toolkit";
import { setOrders, setLoading, updateOrders } from "./ordersSlice";
import axiosInstance, {
  notifyError,
  notifySuccess,
} from "../utility/axiosInstance";
import { orders } from "../utility/api";

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async ({ filterBy }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      let response;
      if (filterBy) {
        response = await axiosInstance.get(`${orders.getOrders}/${filterBy}`);
      } else {
        response = await axiosInstance.get(orders.getOrders);
      }
      dispatch(setOrders(response.data));
      dispatch(setLoading(false));
    } catch (error) {
      notifyError(`Error fetching orders: ${error}`);
      dispatch(setLoading(false));
    }
  }
);

// export const createOffer = createAsyncThunk(
//   "offers/createOffer",
//   async (newOffer, { dispatch }) => {
//     dispatch(setLoading(true));

//     try {
//       await axiosInstance.post(offer.createOffer, newOffer);
//       dispatch(fetchOffers());
//       dispatch(setLoading(false));
//     } catch (error) {
//       notifyError(`Error creating offers: ${error}`);

//       dispatch(setLoading(false));
//     }
//   }
// );

export const deleteOrder = createAsyncThunk(
  "orders/deleteOrder",
  async (id, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      await axiosInstance.delete(`${orders.getOrders}/${id}`);
      dispatch(fetchOrders());
      dispatch(setLoading(false));
      notifySuccess("Order deleted successfully");
    } catch (error) {
      notifyError(`Error deleting order: ${error}`);
      dispatch(setLoading(false));
    }
  }
);

export const updateOrder = createAsyncThunk(
  "orders/updateOrder",
  async ({ id, updatedOrder }, { dispatch }) => {
    dispatch(setLoading(true));

    try {
      await axiosInstance.put(`${orders.updateOrders}/${id}`, updatedOrder);
      dispatch(fetchOrders());
      dispatch(setLoading(false));
      notifySuccess("Order updated successfully");
    } catch (error) {
      notifyError(`Error updating order: ${error}`);
      dispatch(setLoading(false));
    }
  }
);

export const deliverOrder = createAsyncThunk(
  "orders/deliverOrder",
  async ({ id, updatedOrder }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
       await axiosInstance.put(
        `${orders.deliverOrder}/${id}`,
        updatedOrder
      );
      dispatch(setLoading(false));
      dispatch(fetchOrders())
      notifySuccess(`Order has been marked ${updatedOrder?.deliveryStatus}`);
    } catch (error) {
      //   notifyError(`Error updating order: ${error}`);
      dispatch(setLoading(false));
    }
  }
);
