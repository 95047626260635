import React from "react";
import PrimaryButton from "../../utils/buttons/primaryButton";
import { IoIosCheckmarkCircle } from "react-icons/io";

function CloseCard({ data }) {
  const {
    user: { lastName, email, phone, firstName },
    _id,
    serviceRequest,
    car: { brand, model },
    requestedAt: { date, proposedDate },
    status: serviceStatus,
    payment:{paymentStatus},
  } = data;
 

  return (
    <div className="rounded-lg p-6 min-w-[350px] mx-auto bg-[#EDEDEDED]">
      <div className="space-y-4 mb-4 p-3 bg-white rounded-lg shadow-md max-w-full">
        <div className="flex justify-between items-center border-b pb-2">
          <span className="font-semibold text-gray-800 text-sm w-40">Name:</span>
          <span className="text-gray-600 text-sm">
            {firstName + " " + lastName}
          </span>
        </div>

        <div className="flex justify-between items-center border-b pb-3">
          <span className="font-semibold text-gray-800 text-sm">Email Id:</span>
          <span className="text-gray-600 text-sm">{email}</span>
        </div>

        {phone ? (
          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">
              Phone Number:
            </span>
            <span className="text-gray-600 text-sm">{phone}</span>
          </div>
        ) : null}

        <div className="flex justify-between items-center border-b pb-3">
          <span className="font-semibold text-gray-800 text-sm">Model:</span>
          <span className="text-gray-600 text-sm">
            {brand} {model}
          </span>
        </div>
        <div className="flex justify-between items-center border-b pb-3">
          <span className="font-semibold text-gray-800 text-sm">
            Service Requests:
          </span>
          <div className="flex flex-col ">

          {serviceRequest?.map(({ service: { serviceName } }, key) => {
            if (key === serviceRequest.length-1) {
              return (
                <span className="text-gray-600 text-sm">{serviceName}</span>
              );
            } else {
              return (
                <span className="text-gray-600 text-sm">{serviceName},</span>
              );
            }
          })}
          </div>
        </div>
        <div className="flex justify-between items-center border-b pb-3">
          <span className="font-semibold text-gray-800 text-sm">
            Date of service:
          </span>
          <span className="text-gray-600 text-sm">
            {new Date(date).toLocaleString()}
          </span>
        </div>
        
        {proposedDate ? (
          <div className="flex justify-between items-center border-b pb-3">
            <span className="font-semibold text-gray-800 text-sm">
              Proposed Date:
            </span>
            <span className="text-gray-600 text-sm flex items-center gap-2">
              {new Date(proposedDate).toLocaleString()}
              {data?.requestedAt.status === "rescheduleConfirmed" && (
                <span className="text-green-600">
                  <IoIosCheckmarkCircle />
                </span>
              )}
            </span>
          </div>
        ) : null}
        <div className="flex justify-between items-center border-b pb-3">
          <span className="font-semibold text-gray-800 text-sm">
            Payment status
          </span>
          <span className="text-gray-600 text-sm">
            {paymentStatus === "completed" ? "Completed" : "Pending"}
          </span>
        </div>
        <div className="flex flex-col gap-5 items-center">
          {/* <PrimaryButton
            type="button"
            //   onClick={onSRReschedule}
            className={`hover:bg-[#990000] text-white font-semibold p-0`}
            title={"close"}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default CloseCard;
