import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
  name: "customers",
  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    setCustomers: (state, action) => {
      state.list = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setCustomers, setLoading } = customerSlice.actions;
export default customerSlice.reducer;
