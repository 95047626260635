// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import SecondaryButton from "../utils/buttons/secondaryButton";
import {
  alertIcon,
  icons8crossFilled,
  passwordIcon,
  profileIcon,
  searchIcon,
} from "../../assets";
import { NavLink, useNavigate } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import InputField from "../form/input";
import PrimaryButton from "../utils/buttons/primaryButton";
import Notification from "../Notifications/Notifications";
// import { FaBell } from "react-icons/fa";
import { MdNotificationsActive } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { markAsAllRead } from "../../redux/notificationsThunk";
import { fetchUserDetails, updateUserDetails } from "../../redux/userThunk";
import { notifyWarning } from "../../utility/axiosInstance";
import { icons } from "../../utility/icons";
import {
  setOrderSelected,
  setServiceOptionSelected,
  setServiceRequestSelected,
  setUsedCarSelected,
  setCustomersOptionSelected,
  setOffersOptionSelected,
  setReminderOptionSelected,
  setSparePartsOptionSelected,
} from "../../redux/searchSlice";
import ProtectedTabs from "../ProtectedTabs/ProtectedTab";
import { HiReceiptTax } from "react-icons/hi";
import { fetchTax } from "../../utility/api";
import { TaxCalculator } from "./TaxCalculater";

const ImageUser = () => {
  return (
    <div className="h-[40px] w-[40px]  overflow-hidden rounded-full">
      <img
        className="h-full w-full bg-cover cursor-pointer"
        src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
        alt=""
      />
    </div>
  );
};

const Navbar = () => {
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [showUserModalTab, setShowUserModalTab] = useState<number>(1);
  const [notificationsSeen, setNotificationsSeen] = useState(false);
  const [selectedTaxInfo, setSelectedTaxInfo] = useState(null);
  const [taxData, setTaxData] = useState([]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const { list, loading, notificationCount } = useSelector(
    (state) => state.notifications
  );

  const { list: userDetails, loading: userDetailsLoader } = useSelector(
    (state) => state.user
  );

  const search = useSelector((state) => state.search);

  useEffect(() => {
    dispatch(fetchUserDetails());
  }, [dispatch]);

  useEffect(() => {
    setFormData({ ...userDetails });
  }, [userDetails]);

  const navigate = useNavigate();

  const handleSearch = () => {
    if (search.serviceRequestSelected) {
      dispatch(
        setServiceRequestSelected({ isActive: true, searchText: searchInput })
      );
    }
    if (search.orderSelected) {
      dispatch(setOrderSelected({ isActive: true, searchText: searchInput }));
    }
    if (search.serviceOptionSelected) {
      dispatch(
        setServiceOptionSelected({ isActive: true, searchText: searchInput })
      );
    }

    if (search.usedCarSelected) {
      dispatch(setUsedCarSelected({ isActive: true, searchText: searchInput }));
    }

    if (search.sparePartsOptionSelected) {
      dispatch(
        setSparePartsOptionSelected({ isActive: true, searchText: searchInput })
      );
    }

    if (search.offersOptionSelected) {
      dispatch(
        setOffersOptionSelected({ isActive: true, searchText: searchInput })
      );
    }

    if (search.reminderOptionSelected) {
      dispatch(
        setReminderOptionSelected({ isActive: true, searchText: searchInput })
      );
    }
    if (search.customersOptionSelected) {
      dispatch(
        setCustomersOptionSelected({ isActive: true, searchText: searchInput })
      );
    }
  };

  useEffect(() => {
    if (searchInput == "") {
      if (search.serviceRequestSelected) {
        dispatch(setServiceRequestSelected({ isActive: true, searchText: "" }));
      }
      if (search.orderSelected) {
        dispatch(setOrderSelected({ isActive: true, searchText: "" }));
      }

      if (search.serviceOptionSelected) {
        dispatch(setServiceOptionSelected({ isActive: true, searchText: "" }));
      }
      if (search.usedCarSelected) {
        dispatch(setUsedCarSelected({ isActive: true, searchText: "" }));
      }
      if (search.sparePartsOptionSelected) {
        dispatch(setUsedCarSelected({ isActive: true, searchText: "" }));
      }

      if (search.reminderOptionSelected) {
        dispatch(setReminderOptionSelected({ isActive: true, searchText: "" }));
      }
      if (search.customersOptionSelected) {
        dispatch(
          setCustomersOptionSelected({ isActive: true, searchText: "" })
        );
      }
    }
  }, [searchInput]);

  const ref = useRef(null);

  const handleClickOutside = (event: Event) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target)) {
      setShowProfileMenu(false);
    }
  };

  const getChangedFields = (originalData, formData) => {
    const changedFields = {};

    for (const key in formData) {
      if (formData[key] !== originalData[key]) {
        changedFields[key] = formData[key];
      }
    }

    console.log(changedFields);

    return changedFields;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdateUserDetails = () => {
    const changes = getChangedFields(userDetails, formData);

    if (Object.keys(changes).length === 0) {
      notifyWarning("No Changes");
      return;
    }

    dispatch(
      updateUserDetails({
        id: formData?._id,
        updatedUserDetails: changes,
      })
    );
  };

  const handleUpdateTaxDetails = (details) => {
    dispatch(
      updateUserDetails({
        id: formData?._id,
        updatedUserDetails: {
          taxDetails: details,
        },
      })
    );
  };

  useEffect(() => {
    (async () => {
      const response = await fetchTax("all");
      setTaxData(response?.selectedTax);
    })();
  }, []);

  document.addEventListener("click", handleClickOutside, true);

  const navLinks = [
    {
      path: "/orderHistory",
      title: "Order History",
    },

    {
      path: "/enquiries",
      title: "Enquiries",
    },
  ];

  return (
    <div className="bg-primary py-5 ">
      <div className="flex justify-end gap-10 items-center">
        <ProtectedTabs>
          {navLinks?.map((ele) => {
            return(
              <NavLink
                className={"rounded-lg relative"}
                to={ele.path}
                style={({ isActive }) => ({
                  color: isActive? "#990000" : "white",
                  backgroundColor: isActive? "black" : "#252525",
                })}
              >
                {({ isActive }) => (
                  <div className="flex gap-2">
                    <SecondaryButton
                      title={ele.title}
                      onClick={() => {
                        console.log(`${ele.title} button clicked`);
                      }}
                      fontSize="18px"
                      width={"200px"}
                      textColor={"black"}
                      bgColor={"white"}
                      height="50px"
                    />
                  </div>
                )}
              </NavLink>
            )
          })}

          {/* <NavLink
            className={"rounded-lg relative"}
            to={"/orderHistory"}
            style={({ isActive }) => ({
              // color: isActive ? "#990000" : "white",
              // backgroundColor: isActive ? "black" : "#252525",
            })}
          >
            {({ isActive }) => (
              <div className="flex gap-2">
                <SecondaryButton
                  title={"Order Details"}
                  onClick={() => {
                    console.log("Order Details button clicked");
                  }}
                  fontSize="18px"
                  width={"200px"}
                  textColor={"black"}
                  bgColor={"white"}
                  height="50px"
                />
              </div>
            )}
          </NavLink> */}
        </ProtectedTabs>

        <div className="relative flex items-center">
          <input
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search"
            type="text"
            className="py-3 pl-2 focus:outline-none text-white text-lg placeholder:text-lg placeholder:text-white pr-[50px] bg-gray-700 border-white border-[1px] w-[250px] rounded-2xl"
          />
          <div
            onClick={handleSearch}
            className="absolute right-[10px] cursor-pointer"
          >
            <img className="" src={searchIcon} alt="" />
          </div>
        </div>

        <div className="h-[50px] relative rounded-lg flex justify-center items-center w-[50px] bg-gray-700">
          <button
            disabled={showNotification}
            className="py-4 px-1 relative border-2 border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out"
            aria-label="Cart"
            onClick={() => {
              setNotificationsSeen(true);
              setShowNotification(true);
              dispatch(markAsAllRead());
            }}
          >
            <MdNotificationsActive size={30} className="text-white" />

            {notificationCount > 0 && (
              <span className="absolute inset-0 object-right-top -mr-6">
                <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                  {notificationCount}
                </div>
              </span>
            )}
          </button>

          {showNotification && (
            <div className="absolute top-[50px] z-50 min-w-[370px] shadow-2xl">
              <div
                onClick={() => {
                  setShowNotification(false);
                }}
                className="bg-borderColor uppercase relative border-lightBorderColor rounded-t-md border-[1px] font-medium border-b-[0px] text-white py-3 flex justify-center"
              >
                notifications
                <div className="h-[20px] absolute right-1 top-1 cursor-pointer w-[20px] bg-red-600 text-white flex justify-center items-center rounded-full">
                  X
                </div>
              </div>
              <div className="bg-white overflow-auto max-h-[500px] no-scrollbar">
                <Notification />
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center gap-3">
          <p className="text-white font-medium text-base xl:text-xl">
            {formData?.firstName + " " + formData?.lastName}
          </p>
          <div className="h-[30px] w-[30px] xl:h-[50px] xl:w-[50px] overflow-hidden rounded-full">
            <img
              className="h-full w-full bg-cover cursor-pointer"
              src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
              alt=""
              onClick={() => {
                setShowProfileMenu(true);
              }}
            />
          </div>
        </div>

        {showProfileMenu && (
          <>
            <div
              ref={ref}
              className="w-[250px] z-[50] bg-gray-100 cursor-pointer shadow-2xl absolute py-2 rounded-xl top-[90px]"
            >
              <div
                onClick={() => {
                  setShowProfileMenu(false);
                  setShowUserModal(true);
                  setShowUserModalTab(1);
                }}
                className="border-b-[1px] border-[#ccc] text-xl py-1 px-2 font-medium cursor-pointer"
              >
                <span className="flex  items-center gap-2">
                  {icons?.settings} Manage Account
                </span>
                {/* View Profile */}
              </div>
              <div
                onClick={() => {
                  navigate("/");

                  localStorage.removeItem("token");
                  window.location.reload();
                }}
                className="cursor-pointer py-1 px-2 text-xl font-medium"
              >
                <span className="flex items-center gap-2 text-[#990000]">
                  {icons?.logOut} Logout
                </span>
              </div>
            </div>
          </>
        )}

        {showUserModal && (
          <Dialog
            open={showUserModal}
            onClose={() => setShowUserModal(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black/30 flex w-screen items-center justify-center p-4">
              <Dialog.Panel className="w-full flex justify-center items-center rounded">
                <div className="h-[90vh] w-[90%] relative overflow-auto">
                  <div
                    style={{
                      transform: "translate(-50%)",
                    }}
                    className="text-lg pt-2 px-3 w-[400px] rounded-b-lg py-5 fixed left-1/2 h-[83px] bg-[#252525]"
                  >
                    <div className="flex justify-center gap-20 rounded-xl pt-3 text-white">
                      {/* <div className="bg-redBackground rounded-xl px-3 py-2">
                        Profile
                      </div>
                      <div className="bg-redBackground px-3 py-2">Profile</div> */}

                      <SecondaryButton
                        bgColor={`${showUserModalTab === 1 ? "#990000" : ""}`}
                        onClick={() => {
                          setShowUserModalTab(1);
                        }}
                        beforeIcon={profileIcon}
                        title={"Profile"}
                        textColor="white"
                        width="150px"
                        height="45px"
                        border={false}
                      />
                      <SecondaryButton
                        bgColor={`${showUserModalTab === 2 ? "#990000" : ""}`}
                        onClick={() => {
                          setShowUserModalTab(2);
                        }}
                        // beforeIcon={<HiReceiptTax size={30}/>}
                        title={"Add Tax"}
                        textColor="white"
                        width="150px"
                        height="45px"
                        border={false}
                      />
                    </div>
                  </div>
                  <div className=" pb-5 bg-white">
                    {/* <div
                      onClick={() => {
                        setShowUserModal(false);
                      }}
                      className="absolute h-[20px] w-[20px] bg-redBackground text-white flex justify-center items-center right-1 top-1 rounded-full cursor-pointer"
                    >
                      x
                    </div> */}

                    <img
                      src={icons8crossFilled}
                      className="w-8 h-8 absolute right-2 top-2 rounded-full cursor-pointer"
                      onClick={() => {
                        setShowUserModal(false);
                      }}
                    />
                    <div className="pt-[80px]">
                      {showUserModalTab === 1 ? (
                        <>
                          <div className="">
                            <div className="flex justify-center items-center pt-5">
                              <img
                                className="h-[150px]  w-[150px] rounded-full bg-cover cursor-pointer"
                                src="https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg"
                                alt=""
                              />
                            </div>
                            <div className="grid grid-cols-2 gap-10 p-20">
                              <InputField
                                title={"First Name"}
                                name="firstName"
                                value={formData?.firstName}
                                onChange={handleInputChange}
                                placeholder={"First Name"}
                                label={"First Name"}
                              />
                              <InputField
                                title={"Last Name"}
                                name="lastName"
                                onChange={handleInputChange}
                                value={formData?.lastName}
                                placeholder={"Last Name"}
                                label={"Last Name"}
                              />
                              <InputField
                                title={"Email"}
                                onChange={handleInputChange}
                                value={formData?.email}
                                name="email"
                                placeholder={"Email"}
                                label={"Email"}
                              />
                              <InputField
                                title={"Phone"}
                                placeholder={"Phone"}
                                onChange={handleInputChange}
                                value={formData?.phone}
                                name="phone"
                                label={"phone"}
                              />
                              {/* <div className="col-span-2">
                                <InputField
                                  title={"Location"}
                                  placeholder={"Location"}
                                  label={"Location"}
                                />
                              </div> */}
                            </div>
                            <div className="flex justify-center gap-20">
                              <PrimaryButton
                                loading={userDetailsLoader}
                                bgColor="#990000"
                                onClick={handleUpdateUserDetails}
                                title={"Save"}
                                textColor="white"
                                width="150px"
                                height="45px"
                              />
                              <SecondaryButton
                                textColor="black"
                                bgColor="white"
                                onClick={() => {
                                  setShowUserModal(false);
                                }}
                                title={"Cancel"}
                                width="150px"
                                height="45px"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="">
                            <div className="grid p-20">
                              <TaxCalculator
                                id={formData?._id}
                                setSelectedTaxInfo={setSelectedTaxInfo}
                                selectedTaxInfo={selectedTaxInfo}
                                data={taxData}
                              />
                            </div>
                            <div className="flex justify-center gap-20">
                              <PrimaryButton
                                loading={userDetailsLoader}
                                bgColor="#990000"
                                onClick={() =>
                                  handleUpdateTaxDetails(selectedTaxInfo)
                                }
                                title={"Save"}
                                textColor="white"
                                width="150px"
                                height="45px"
                              />

                              <SecondaryButton
                                textColor="black"
                                bgColor="white"
                                onClick={() => {
                                  setShowUserModal(false);
                                }}
                                title={"Cancel"}
                                width="150px"
                                height="45px"
                                fontSize="16px"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default Navbar;
