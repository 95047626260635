import { useState, useEffect } from "react";
import PrimaryButton from "../utils/buttons/primaryButton";
import { icons } from "../../utility/icons";
import { baseURL } from "../../utility/axiosInstance";

export const OfferCard = ({ item, onEdit, onDelete }) => {
  const [imageUrl, setImageUrl] = useState(null);

  const url = baseURL.split("/api");

  useEffect(() => {
    if (item?.icon) {
      setImageUrl(`${url[0]}/${item?.icon}`);
    }
  }, [item]);

  console.log(`${url[0]}/${item.icon}`, "imageUrl");

  return (
    <div className="flex flex-col rounded-[15px] shadow-md border border-gray-300 p-2">
      <div className="relative">
        <img
          src={`${url[0]}/${item?.icon?.substring(39)}` || ""}
          className="h-40 w-full object-cover rounded-t-[15px]"
          alt={item?.title}
        />
      </div>
      <div className="p-4">
        <p className="text-lg font-semibold mb-2">{item?.title}</p>
        <p className="text-sm text-gray-700 mb-2">{item?.description}</p>
        <p className="text-md font-bold mb-2">
          Discount: {item?.discount}{" "}
          {item?.discountType === "percentage" ? "%" : "₹"}
        </p>
        <p className="text-sm text-gray-600 mb-2">
          Minimum Order Amount: {item?.minOrderAmount}
        </p>
        <p className="text-sm text-gray-600 mb-2">
          Applicable Categories: {item?.applicableCategories.join(", ")}
        </p>
        <p className="text-sm text-gray-600 mb-2">Code: {item?.code}</p>
        <p className="text-sm text-gray-600 mb-2">
          Valid From: {new Date(item?.validFrom).toLocaleDateString()}
        </p>
        <p className="text-sm text-gray-600">
          Valid To: {new Date(item?.validTo).toLocaleDateString()}
        </p>
      </div>
      <div className="flex justify-around py-2">
        <PrimaryButton
          title={"Edit"}
          onClick={() => onEdit(item)}
          textColor={"white"}
          bgColor={"#990000"}
          icon={icons?.editIcon}
        />
        <PrimaryButton
          title={"Delete"}
          onClick={() => onDelete(item._id)}
          textColor={"black"}
          bgColor={"gray-300"}
          icon={icons?.deleteIcon}
        />
      </div>
    </div>
  );
};
