import React, { createContext, useEffect, useState } from "react";
import WeekSettingComponent from "./components/WeekSettingComponent";
import CalenderComponent from "./components/CalenderComponent";
import ServiceListComponent from "./components/ServiceListComponent";
import { months } from "moment";
import PrimaryButton from "../../utils/buttons/primaryButton";
import axiosInstance, { notifyError } from "../../../utility/axiosInstance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
export const ServiceSettingContext = createContext({});
const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const ServiceSettingIndex = () => {
  const { serviceSettingInfo, loading, serviceAdditionInfo } = useSelector(
    (state) => state?.services
  );

  const handleEDAToggle = async () => {
    try {
      const response = await axiosInstance.put(
        "servicesSetting/updateServiceSettingInfo",
        {
          // EDTimeSlot:!EDTimeSlot,
        }
      );

      if (response?.status === 200) {
        toast.success("Changed the Status");
        // setInfo(response.data.parkingLot);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [weeklyCheckbox, setWeeklyCheckbox] = useState({
    Monday: { day: true, all: false },
    Tuesday: { day: true, all: false },
    Wednesday: { day: true, all: false },
    Thursday: { day: true, all: false },
    Friday: { day: true, all: false },
    Saturday: { day: true, all: false },
    Sunday: { day: true, all: false },
  });
  const [calenderView, setCalenderView] = useState("week");
  const [workingTime, setWorkingTime] = useState({
    start: "9:00",
    end: "18:00",
  });
  const [workingDayTime, setWorkingDayTime] = useState([]);
  const [availableWorkingDays, setAvailableWorkingDays] = useState([]);

  const [services, setServices] = useState([]);
  const [dateValue, onDateChange] = useState(new Date());


  useEffect(() => {
    if (serviceAdditionInfo?.workingDetails) {
      const { workingTime, weeklySetting, calenderView } =
        serviceAdditionInfo?.workingDetails;

      setWorkingTime(workingTime);

      setWeeklyCheckbox(weeklySetting);
      setCalenderView(calenderView);
    }
  }, [serviceAdditionInfo]);

  return (
    <ServiceSettingContext.Provider
      value={{
        services,
        setServices,
        weeklyCheckbox,
        weekdays,
        setWeeklyCheckbox,
        dateValue,
        onDateChange,
        calenderView,
        setCalenderView,
        workingTime,
        setWorkingTime,
        workingDayTime,
        setWorkingDayTime,
        availableWorkingDays,
        setAvailableWorkingDays,
      }}
    >
      <div className="py-8 flex flex-wrap justify-around gap-10">
        <WeekSettingComponent />
        <ServiceListComponent />
        <CalenderComponent />
      </div>
      {/* <PrimaryButton
        width="120px"
        title={"Save All"}

        // onClick={handleUpdatePKL}
        // loading={loading}
      /> */}
    </ServiceSettingContext.Provider>
  );
};

export default ServiceSettingIndex;
